import makeSwal from '../helpers';

_(document).ready(() => {

  flatpickr("#turnstile-update-date", {
      dateFormat: "Y-m-d"
  });

  _('#turnstile-update-data').on('click', (elem) => {
    let url = _(elem.target).attr('data-url');
    let date = _('#turnstile-update-date').val();

    if (date.length) {
        url += '?date=' + date;
    }

    update(url);
  });

  function update(url) {
    axios.post(url)
      .then((response) => {
        makeSwal(response.data);
        const dataTable = $('#panel-turnstile-table').dataTable().api();
        dataTable.clear();
        dataTable.draw();
        ;
      })
      .catch((axiosError) => {
        makeSwalValidation(axiosError);
      });
  }
});
