import SignaturePad from 'signature_pad';
import makeSwal from './helpers';

_(document).ready(() => {
  if (_('.choices-deposits').length) {
    const selectBox = new Choices('.choices-deposits', {
      sortFilter(a, b) {
        return b.value - a.value;
      },
    });
  }

  if ($('#money-transfer-select').length) {
    const moneyTransferChoices = new Choices('#money-transfer-select', {
      sortFilter(a, b) {
        return b.value - a.value;
      },
    });
  }

  // Start signature feature
  const signatureCanvas = document.querySelectorAll('.user-signature');

  const signatureObjects = {};
  signatureCanvas.forEach((canvas) => {
    const cv = canvas;
    cv.width = _(canvas).parent()[0].clientWidth;
    cv.height = _(canvas).parent()[0].clientHeight;
    signatureObjects[canvas.id] = new SignaturePad(canvas);
  });

  _('.canvas-clear-action-button').on('click', (elem) => {
    const clearButtonId = _(elem.target).attr('data-signature-id');
    signatureObjects[clearButtonId].clear();
  });

  _('.canvas-undo-action-button').on('click', (elem) => {
    let undoButtonId = _(elem.target).attr('data-signature-id');
    const data = signatureObjects[undoButtonId].toData();

    if (data) {
      data.pop(); // remove the last dot or line
      signatureObjects[undoButtonId].fromData(data);
    }
  });

  /**
   * Do the hmtl code for each coin input
   * @param coins
   * @returns {string}
   */
  function htmlString(response) {
    let htmlString = '';
    let totalAmount = 0;
    response.data.forEach((sealBag) => {
      htmlString += '<tr>';
      htmlString += `<td>${sealBag.number}</td>`;
      htmlString += `<td class=seal-bag-total-amount-${sealBag.id}">${ Math.round(sealBag.amount) }</td>`;
      htmlString += `<td><input class="seal-bag-check" type="checkbox" name="seal_bags[]" value="${ sealBag.id }" checked></td>`;
      htmlString += '</tr>';

      totalAmount += parseInt(sealBag.amount);
    });
    _('#seal-bags-total').html(`${totalAmount}`);
    return htmlString;
  }

  // Send the signatures
  _('#deposits-sign').on('submit', (e) => {
    e.preventDefault();
    let filled = true;
    const data = new FormData(e.target);
    // Only send the request if there are both signatures.

    const signatureKeys = Object.keys(signatureObjects);
    const signatureValues = Object.values(signatureObjects);
    for (let signatureIndex = 0; signatureIndex < signatureKeys.length; signatureIndex += 1) {
      if (signatureValues[signatureIndex].isEmpty()) {
        filled = false;
      } else {
        const dataUrl = signatureValues[signatureIndex].toDataURL('image/png');
        data.append(signatureKeys[signatureIndex], dataUrl);
      }
    }

    if (filled) {
      axios.post(_(e.target).attr('action'), data)
        .then((response) => {
          makeSwal(response.data);
        })
        .catch((axiosError) => {
          makeSwalValidation(axiosError);
        });
    } else {
      makeSwal({
        title: 'Please provide both signatures first',
        type: 'error',
      });
    }
  });
  // End signature feature

  // when the document was loaded we need to query the actual currency coins
  if (_('#deposit-branch-dropdown').length) {
    axios.post(_('#deposit-branch-dropdown').attr('data-url-detail').replace('_branchId_', _('#deposit-branch-dropdown').val()))
      .then((response) => {
        _('#content-table tbody').html(htmlString(response.data));
        bulkSealbag();
      });
  }

  // when the user changes the currency we need to query the actual currency coins
  _('#deposit-branch-dropdown').on('change', (e) => {
    axios.post(_(e.target).attr('data-url-detail').replace('_branchId_', _(e.target).val()))
      .then((response) => {
        $('#content-table tbody').html(htmlString(response.data));
        bulkSealbag();
      });

    // select default money transfer company
    axios.get(_(e.target).attr('data-url-money-transfer') + '?branch=' + _(e.target).val())
      .then((response) => {
        moneyTransferChoices.setValue([response.data.id, response.data.name]);
      });
  });

  // delete a deposit
  _('#deposit-delete').on('click', (event) => {
    swal({
      title: 'Delete',
      text: 'Do you really want to delete the deposit?',
      type: 'warning',
      confirmButtonText: 'Confirm',
      showCancelButton: true,
      confirmButtonColor: '#e74c3c',
    }).then((result) => {
      if (result) {
        axios.delete(_(event.target).attr('data-url'))
          .then((response) => {
            makeSwal(response.data);
          });
      }
    });
  });

  // add a deposit
  _('#deposits-store').on('submit', (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    axios.post(_(e.target).attr('action'), data)
      .then((response) => {
        makeSwal(response.data);
      })
      .catch((axiosError) => {
        makeSwalValidation(axiosError);
      });
  });

// add a new money transfer company
  _('#add-money-transfer').on('click', (e) => {
    e.preventDefault();
    swal({
      title: 'Add new money transfer company',
      input: 'text',
      inputPlaceholder: 'Type the company name',
      type: 'info',
      showCancelButton: true,
      inputValidator: value => new Promise((resolve, reject) => {
        if (value !== '') {
          resolve();
        } else {
          reject('Company name field is required');
        }
      }),
    }).then((result) => {
      const values = {
        company: result,
      };
      if (result) {
        axios.post(_('#add-money-transfer').attr('data-url'), values)
          .then((response) => {
            makeSwal(response.data);
          });
      }
    });
  });

  // show index deposit table
  if (!$.fn.dataTable.isDataTable('#deposits-index-table')) {
    $('#deposits-index-table').dataTable({
      order: [[3, 'asc']],
      ajax: {
        url: _('#deposits-index-table').attr('data-url-table'),
      },
      columns: [
        { data: 'created_at' },
        { data: 'branch' },
        { data: 'amount', className: 'tar' },
        { data: 'user' },
        { data: 'country' },
        { data: 'signed', render: (data) => {
            if(data == 1){
              return `<span class="label success round">Yes</span>`;
            }
            return `<span class="label warning round">No</span>`;
          } },
        { data: null,
          width: '120px',
          sortable: false,
          searchable: false,
          render: (data, type, row) => `<a class="table-show-link" href="${$('#deposits-index-table').attr('data-url-detail').replace('_id_', row.id)}"><i class="fa fa-search"></i> Details</a>` },
      ],
      order: [[1, 'desc']],
    });
  }

  if (!$.fn.dataTable.isDataTable('#deposits-seal-bag-table')) {
    $('#deposits-seal-bag-table').dataTable({
      order: [[1, 'asc']],
      ajax: {
        url: _('#deposits-seal-bag-table').attr('data-url-table'),
      },
      columns: [
        { data: 'created_at' },
        { data: 'number', width: '200px' },
        { data: 'amount' },
        { data: 'user.name', searchable: false,},
        { data: 'locked', render: (data) => {
            if(data == 1){
              return `<span class="label success round">Yes</span>`;
            }
            return `<span class="label warning round">No</span>`;
          } },
        { data: null,
          width: '120px',
          sortable: false,
          searchable: false,
          render: (data, type, row) => `<a class="table-show-link" href="${$('#deposits-seal-bag-table').attr('data-url-detail').replace('_id_', row.id)}"><i class="fa fa-search"></i> Details</a>` },
      ],
      order: [[1, 'desc']],
    });
  }

  // update a deposit
  _('#deposits-update').on('submit', (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    axios.post(_(e.target).attr('action'), data)
      .then((response) => {
        makeSwal(response.data);
      })
      .catch((axiosError) => {
        makeSwalValidation(axiosError);
      });
  });

  // Money transfer company other feature
  if (_('#money-transfer-select').length) {
    const companies = document.querySelector('#money-transfer-select');
    if (companies.options[companies.selectedIndex].innerHTML === 'Other') {
      _('#agent-company-name').attr('class', '');
    }

    _('#money-transfer-select').on('change', (elem) => {
      if (elem.target.options[elem.target.selectedIndex].innerHTML === 'Other') {
        _('#agent-company-name').attr('class', '');
      } else {
        _('#agent-company-name').attr('class', 'hide');
        _('#money_transfer_agent_company').val('');
      }
    });
  }
});

//bulk seal bag select
function bulkSealbag() {
  _('#seal-bag-all-checkbox').on('click', (element) => {
    document.querySelectorAll('.seal-bag-check').forEach((checkboxElement) => {
      checkboxElement.checked = element.target.checked;
    });
  });
}
