import dragula from 'dragula';
import makeSwal from './helpers';

_(document).ready(() => {
  if (!_("#checklist-section").length) {
    return;
  }
  // var checklistId = $("#branches-container").serializeArray()[0]["value"];
  // using dragula to make the items within the divs, dragable.
  let drake = dragula(document.querySelector('#questions-pool'));

  _('#checklist-show-delete').on('submit', (event) => {
    event.preventDefault();
    swal({
      title: 'Delete checklist',
      text: 'Do you really want to delete the checklist?',
      type: 'warning',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      confirmButtonColor: '#e74c3c',
    }).then((result) => {
      if (result) {
        axios.delete(_(event.target).attr('action'))
          .then((response) => {
            makeSwal(response.data);
          });
      }
    });
  });

  $("#send-email-link").click(function () {
    swal({
      title: 'Send checklist',
      text: 'Enter an email address to share the results of the checklist with',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Send',
      inputAttributes: {
        name: 'email',
        id: 'email'
      },
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      preConfirm: function preConfirm(value) {
        return new Promise(function (resolve, reject) {
          var emailUrl = $('#send-email-link').attr('data-url-email');
          $.ajax({
            type: 'POST',
            url: emailUrl,
            data: { email: $('#email').val() },
            success: function success(response) {
              window.location.replace(response);
            },
            error: function error(a, b, c) {
              if (a.responseJSON.message) {
                reject(a.responseJSON.message.toString());
              } else {
                reject(a.responseJSON.toString());
              }
            }
          });
        });
      },
      allowOutsideClick: false
    }).then(function (response) {
      Object(__WEBPACK_IMPORTED_MODULE_0__helpers__["a" /* default */])(response);
    });
  });

  /**
   * Do the HTML code for each checklist
   * @param categories
   * @returns {string}
   */
  function categoriesHtmlString(categories) {
    let categoriesHtml = '';
    const categoriesKeys = Object.keys(categories);
    const categoriesValues = Object.values(categories);
    for (let categoryIndex = 0; categoryIndex < categoriesKeys.length; categoryIndex += 1) {
      categoriesHtml += `<li class="accordion-item" data-accordion-item>
                            <a href="#" class="accordion-title">
                              <i class="fa fa-bars" aria-hidden="true"></i> ${categoriesValues[categoryIndex].name}
                            </a>
                            <div class="accordion-content" data-tab-content>
                            <div class="category-trash"><button data-url="/checklists/categories/${categoriesValues[categoryIndex].id}/destroy" class="button radius settings small alert"><i class="fa fa-trash"></i> Delete</button></div>
                            <div id="question-category-${categoriesValues[categoryIndex].id}" class="category-questions checklist-items-container">`;
      const questionsKeys = Object.keys(categoriesValues[categoryIndex].checklist_questions);
      const questionsValues = Object.values(categoriesValues[categoryIndex].checklist_questions);
      for (let questionIndex = 0; questionIndex < questionsKeys.length; questionIndex += 1) {
        categoriesHtml += `<div class="checklist-item" data-display-order="${questionsValues[questionIndex].display_order}" id="question-id-${questionsValues[questionIndex].id}">`;
        categoriesHtml += `<a href="/checklists/questions/${questionsValues[questionIndex].id}"><i class="fa fa-search" aria-hidden="true"></i>Details</a>${questionsValues[questionIndex].question_to_display}</div>`;
      }

      categoriesHtml += '</div></div></li>';
    }

    return categoriesHtml;
  }

  /**
   *  build an object with sort information like
   *  [positions] => Array (
   *  [12] => 1
   *  )
   *  where "1" is the relative position of the question within the list and the array key is the question id
   */
  function setDivsOrder(container) {
    let questionPosition = 0;
    const positions = {};
    container.childNodes.forEach((questionNode) => {
      questionPosition += 1;
      _(questionNode).attr('data-display-order', questionPosition);
      positions[_(questionNode).attr('id').split('-').pop()] = questionPosition;
    });

    return positions;
  }

  /**
   * Create the divs passed dragables
   * @param items containers.
   */
  function makeDragula(items) {
    drake.destroy();
    // using dragula to make the items within the divs, dragable.
    drake = dragula(items);
    drake.containers.push(document.querySelector('#questions-pool'));
    let updateUrl = '';
    let sortPickUrl = '';
    let positions = {};

    drake.on('drop', (el, target, source) => {
      sortPickUrl = _('#categories-container').attr('data-pick-sort-url')
        .replace('_id_', _(target).attr('id').split('-').pop());
      updateUrl = _('#categories-container').attr('data-categories-update-url')
        .replace('_id_', _(target).attr('id').split('-').pop());
      const questions = [];

      // if the drop target isnt the question pool, is a question to category assignment
      if (_(target).attr('id') !== _(source).attr('id') && _(target).attr('id') !== 'questions-pool') {
        target.childNodes.forEach((questionNode) => {
          // get the id from the DOM attribute that has the following structure "question-category-25"
          questions.push(_(questionNode).attr('id').split('-').pop());
        });

        document.querySelector('#questions-pool-count').innerHTML = source.children.length;
        axios.put(updateUrl, { question_ids: questions })
          .then((response) => {
            console.log(response);
          });

        // when an element is coming from question pool we need to order it also.
        positions = setDivsOrder(target);
        // Send the changes to the server
        axios.put(sortPickUrl, { positions })
          .then((response) => {
            console.log(response);
          });
      } else if (_(target).attr('id') === _(source).attr('id') && _(target).attr('id') !== 'questions-pool') {
        // movement within the same dragable
        positions = setDivsOrder(source);

        // Send the changes to the server
        axios.put(sortPickUrl, { positions })
          .then((response) => {
            console.log(response);
          });
      } else if (_(source).attr('id') !== 'questions-pool') {
        updateUrl = _('#categories-container').attr('data-categories-update-url')
          .replace('_id_', _(source).attr('id').split('-').pop());

        document.querySelector('#questions-pool-count').innerHTML = target.children.length;

        axios.put(updateUrl, { question_id: _(el).attr('id').split('-').pop(), question_pool: true })
          .then((response) => {
            console.log(response);
          });
      }
    });
  }

  /**
   * Function that set the event to the delete button on category (after ajax call)
   */
  function deleteCategoryEvent() {
    _('.category-trash').on('click', (event) => {
      swal({
        title: 'Delete category',
        text: 'Do you really want to delete the category? The questions will be moved back to the questions pool.',
        type: 'warning',
        confirmButtonText: 'Delete',
        showCancelButton: true,
        confirmButtonColor: '#e74c3c',
      }).then((result) => {
        if (result) {
          axios.delete(_(event.target).attr('data-url'))
            .then((response) => {
              console.log(_(event.target).attr('data-url'));
              makeSwal(response.data);
            });
        }
      });
    });
  }

  function fillAfterBranchTypeChange(response) {
    _('#categories-container').html(categoriesHtmlString(response.data));
    deleteCategoryEvent();
    // on ajax response we need to reassing all the events for foundation an dragula.
    Foundation.reInit(['accordion']);
    const items = [].slice.apply(document.querySelectorAll('.category-questions'));
    items.push(document.querySelector('#questions-pool'));

    makeDragula(items);
  }

  function loadUnusedQuestions(response){
    let html = '';
    document.querySelector('#questions-pool-count').innerHTML = response.data.length;
    response.data.forEach((rowQuestion) => {
      const questionDetail = _('#questionPoolWrapper').attr('data-url-question-detail').replace('_id_', rowQuestion.id);
      html += `<div class="checklist-item" id="question-id-${rowQuestion.id}">
                ${rowQuestion.question_to_display}
                <a href="${questionDetail}">
                  <i class="fa fa-search" aria-hidden="true"></i> Details
                </a>
              </div>`;
    });

    _("#questions-pool").html(html);
  }

  if (_('#questionPoolWrapper').length) {
    axios.get(_('#questionPoolWrapper').attr('data-categories-url'))
      .then((response) => {
        fillAfterBranchTypeChange(response);
      });

    axios.get(_('#questionPoolWrapper').attr('data-url-unused-questions'))
      .then((response) => {
        loadUnusedQuestions(response);
      });
  }

  // add a new category to the branch
  _('#add-checklist-button').on('click', (e) => {
    e.preventDefault();
    swal({
      title: 'Add new category',
      input: 'text',
      inputPlaceholder: 'Type the category name',
      type: 'info',
      showCancelButton: true,
      inputValidator: value => new Promise((resolve, reject) => {
        if (value !== '') {
          resolve();
        } else {
          reject('Category name field is required');
        }
      }),
    }).then((result) => {
      if (result) {
        const values = {
          category_name: result,
        };
        axios.post(_('#questionPoolWrapper').attr('data-add-categories-url'), values)
          .then((response) => {
            const newDBCategory = response.data;
            const newChecklistHTML = `
              <li class="accordion-item" data-accordion-item>
                <a href="#" class="accordion-title">
                  <i class="fa fa-bars" aria-hidden="true"></i> ${newDBCategory.name}
                </a>
                <div class="accordion-content" data-tab-content>
                  <div class="category-trash">
                    <button data-url="/checklists/categories/${newDBCategory.id}/destroy" class="button radius settings small alert">
                      <i class="fa fa-trash"></i> Delete
                    </button>
                  </div>
                  <div id="question-category-${newDBCategory.id}" class="category-questions checklist-items-container"></div>
                </div>
              </li>`;
            _('#categories-container').append(newChecklistHTML);
            // on ajax response we need to reassing all the events for foundation an dragula.
            Foundation.reInit(['accordion']);
            const items = [].slice.apply(document.querySelectorAll('.category-questions'));
            items.push(document.querySelector('#questions-pool'));

            makeDragula(items);
            deleteCategoryEvent();
          });
      }
    });
  });

  _('#add_photo_slot').on('click', () => {
    _('#photo_field').append('<input class="question--input question--file" type="file" name="files[]" style="display: block;"/>');
  });
  // _('#add_photo_slot').trigger('click');

  _('#updateChecklistTemplateForm').on('submit', () => {
    axios.post(_('#updateChecklistTemplateForm').attr('action'), $('#updateChecklistTemplateForm').serialize(), () => {
      window.location = window.location.href;
    });
  })

  if (_('#start-checklist-button').length) {
    _('#start-checklist-button').attr('href', _('#checklist-branch-select').attr('data-url').replace('_id_', _('#checklist-branch-select').val()));
  }

  _('#checklist-branch-select').on('change', (elem) => {
    _('#start-checklist-button').attr('href', _(elem.target).attr('data-url').replace('_id_', _(elem.target).val()));
  });

  if (!$.fn.dataTable.isDataTable('#checklists-add-table')) {
    $('#checklists-add-table').dataTable({
      dom : '<t>',
      ajax: {
        url: $('#checklists-add-table').data('url-table'),
        data: (data) => {
          data.branchId = _('#checklist-branch-select').val();
        },
      },
      columns: [
        { data: 'created_at' },
        { data: 'user' },
        { data: 'rating' },
        { data: 'comment' },
        { data: null,
          width: '120px',
          sortable: false,
          searchable: false,
          render: (data, type, row) => {
            if (row.permissions.view) {
              return `<a id="" href="${_('#checklists-add-table').attr('data-url-detail').replace('_id_', row.id)}"><i class="fa fa-search"></i> Details</a>`;
            }

            return '';
          }
        },
      ],
    });
  }

});
