import makeSwal from './helpers';

_('#reset-password').on('click', (e) => {
  e.preventDefault();
  swal({
    title: 'Password Reset',
    text: 'Please enter your e-mail',
    input: 'email',
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    inputAttributes: {
      name: 'name',
      id: 'name',
    },
    cancelButtonText: 'Cancel',
    showLoaderOnConfirm: true,
    preConfirm: value => new Promise((resolve, reject) => {
      const url = $('#reset-password').attr('data-url');
      $.ajax({
        type: 'POST',
        url,
        data: { email: value },
        success: (response) => {
          resolve(response);
        },
        error: (a, b, c) => {
          reject('error message');
        },
      });
    }),
    allowOutsideClick: false,
  }).then((response) => {
    makeSwal(response);
  });
});

_('#loginForm').on('submit', (e) => {
  e.preventDefault();

  const emailValue = $('#email').val();
  const passwordValue = $('#password').val();
  const url = $('#loginForm').attr('data-url-generate');
  const loginUrl = $('#loginForm').attr('data-url-mfa');

  if (!$('#loginForm').attr('data-mfa-enabled')) {
    $.ajax({
      type: 'POST',
      url: loginUrl,
      data: { mfa: null, email: $('#email').val(), password: $('#password').val() },
      success: (response) => {
        window.location.replace(response);
      },
      error: (a, b, c) => {
        if (a.responseJSON.message) {
          swal('Error', a.responseJSON.message.toString(), 'error');
        } else {
          swal('Error', a.responseJSON.toString(), 'error');
        }
      }
    });
  } else {
    $.ajax({
      type: 'POST',
      url,
      data: { email: emailValue, password: passwordValue },
    }).then((response) => {
      if (response.error) {
        swal({
          title: 'Invalid login',
          text: response.error,
          type: 'error',
        });
      } else {
        swal({
          title: 'MFA Token',
          text: 'Please enter your token',
          type: 'info',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Login',
          inputAttributes: {
            name: 'name',
            id: 'name',
          },
          cancelButtonText: 'Cancel',
          showLoaderOnConfirm: true,
          preConfirm: value => new Promise((resolve, reject) => {
            $.ajax({
              type: 'POST',
              url: loginUrl,
              data: { mfa: value, email: $('#email').val(), password: $('#password').val() },
              success: (response) => {
                window.location.replace(response);
              },
              error: (a, b, c) => {
                if (a.responseJSON.message) {
                  reject(a.responseJSON.message.toString());
                } else {
                  reject(a.responseJSON.toString());
                }

              },
            });
          }),
          allowOutsideClick: false,
        }).then((response) => {
          makeSwal(response);
        });
      }
    });
  }

});
