import makeSwal from './helpers';

_(document).ready(() => {
  // add a money transfer company
  $(() => {
    $.ajaxSetup({
      headers: { 'X-CSRF-TOKEN': window.Atabase.csrfToken },
    });
  });

  // create a money transfer company
  _('#money-transfer-store').on('submit', (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    axios.post(_(e.target).attr('action'), data)
      .then((response) => {
        makeSwal(response.data);
      })
      .catch((axiosError) => {
        makeSwalValidation(axiosError);
      });
  });

  // update a money transfer company
  _('#money-transfer-update').on('submit', (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    axios.post(_(e.target).attr('action'), data)
      .then((response) => {
        makeSwal(response.data);
      })
      .catch((axiosError) => {
        makeSwalValidation(axiosError);
      });
  });

  // delete a sealBag
  _('#money-transfer-delete').on('click', (event) => {
    swal({
      title: 'Delete',
      text: 'Do you really want delete the money transfer company?',
      type: 'warning',
      confirmButtonText: 'Confirm',
      showCancelButton: true,
      confirmButtonColor: '#e74c3c',
    }).then((result) => {
      if (result) {
        axios.delete(_(event.target).attr('data-url'))
          .then((response) => {
            makeSwal(response.data);
          });
      }
    });
  });

  if (!$.fn.dataTable.isDataTable('#money-transfer-index-table')) {
    $('#money-transfer-index-table').dataTable({
      ajax: {
        url: $('#money-transfer-index-table').attr('data-url-table'),
        data: (data) => { },
      },
      columns: [
        { data: 'company' },
        { data: 'created_at' },
        { data: null,
          width: '120px',
          sortable: false,
          searchable: false,
          render: (data, type, row) => `<a class="table-show-link" href="${$('#money-transfer-index-table').attr('data-url-detail').replace('_id_', row.id)}"><i class="fa fa-search"></i> Details</a>` },
      ],
    });
  }
});
