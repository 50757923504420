_(document).ready(() => {
  if (! _('#visitorsTurnoverContainer').length) {
    return;
  }

  /**
   * Create the query string based on the _current_ filter values
   */
  function getFilterQueryString() {
    const form = document.getElementById('filterForm');
    const formData = new FormData(form);

    return new URLSearchParams(formData).toString();
  }

  /**
   * Creates the html string for a table row based on the given data
   *
   * @param row
   */
  function drawTableRow(row) {
    const target = _('#visitorsTurnoverTable tbody');

    const html = `
      <tr data-branch-revenue-link="${row.branch.links.revenue}">
        <td>${row.branch.name}</td>
        <td>${row.branch.type.name}</td>
        <td>${row.branch.status.name}</td>
        <td>${row.branch.country.name}</td>
        <td class="branchYTDAmount" style="text-align:right;" data-branch-revenue="${row.years[0].year}">${row.years[0].visits}</td>
        <td class="branchOneYearAgoAmount" style="text-align:right;" data-branch-revenue="${row.years[1].year}">${row.years[1].visits}</td>
        <td class="branchTwoYearsAgoAmount" style="text-align:right;" data-branch-revenue="${row.years[2].year}">${row.years[2].visits}</td>
        <td class="branchThreeYearsAgoAmount" style="text-align:right;" data-branch-revenue="${row.years[3].year}">${row.years[3].visits}</td>
      </tr>
    `;

    _(target).append(html);
  }

  /**
   * (Re)Load the table data
   */
  function loadTable() {
    const table = _('#visitorsTurnoverTable');
    let url = table.attr('data-url');

    url += `?${getFilterQueryString()}`;

    // init loader
    axios.get(url)
      .then((response) => {
        const target = _('#visitorsTurnoverTable tbody');
        _(target).html('');

        if (response.data.length === 0) {
          _(target).html(`<tr><td colspan="8" class="tac">No results found.</td></tr>`);
        } else {
          for (let i in response.data) {
            drawTableRow(response.data[i]);
          }
        }
      });
  }

  _(document).on('click', '[data-branch-revenue]', (e) => {
    e.preventDefault();

    const row = $(e.target).parent('tr');
    const year = $(e.target).closest('[data-branch-revenue]').attr('data-branch-revenue');
    const period = $('select[name=period]').val();

    const url = `${$(row).attr('data-branch-revenue-link')}?year=${year}&period=${period}#panel-revenue`;

    window.location = url;
  });
  /**
   * Clone a filter field to be able to filter on more values for that key
   */
  _('[data-clone]').on('click', (e) => {
    e.preventDefault();

    const wrap = _(e.target).parent().find('.clone-wrap');
    const target = _(`.${_(e.target).attr('data-clone')}`)[0];
    const cloneWrap = document.createElement('div');
    cloneWrap.className = 'clone';
    const closeIcon = document.createElement('i');
    closeIcon.className = 'fa fa-close remove-filter-clone-button';
    const clone = target.cloneNode(true);

    cloneWrap.appendChild(clone);
    cloneWrap.appendChild(closeIcon);
    _(wrap).append(cloneWrap);
  });

  _(window).on('click', '.remove-filter-clone-button', (e) => {
    e.preventDefault();

    _(e.target).parent('.clone').remove();
  });

  /**
   * Submit filter form
   */
  _('form').on('submit', (e) => {
    e.preventDefault();

    loadTable();
  });

  /**
   * Export data for _current_ filter values to CSV
   */
  _('.export-visitors-btn').on('click', (e) => {
    e.preventDefault();
    const table = _('#visitorsTurnoverTable');
    let url = table.attr('data-download');

    url += `?${getFilterQueryString()}`;

    window.location.href = url;
  });

  /**
   * Inititial load
   */
  function initPage() {
    loadTable();

    // flatpickr('input[name=period]', {
    //   dateFormat: 'Y-m',
    // });
  }

  initPage();
});
