import makeSwal from './helpers';

_('#turnstile-update').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#turnstile-store').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#sync-mycharlie-devices').on('click', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('data-url'))
    .then((response) => {
      makeSwal(response.data);
    });
});

// Load turnstile period history
$('.tabs').on('change.zf.tabs', (event) => {
  if ($('#panel-turnstile-periods:visible').length) {
    flatpickr('input[name=start_at]', {
      dateFormat: 'Y-m-d',
    });
    if (!$.fn.dataTable.isDataTable('#panel-turnstile-periods-table')) {
      $('#panel-turnstile-periods-table').dataTable({
        ajax: {
          url: $('#panel-turnstile-periods-table').data('url-table'),
          data: (data) => { },
        },
        order: [[1, 'asc']],
        columnDefs: [
          { type: 'date-euro', targets: 0 },
        ],
        columns: [
          { data: 'start_at' },
          { data: 'end_at' },
          { data: 'gender' },
          { data: 'branch.name', name: 'branch.name' },
          { data: 'visitor_type', name: 'visitor_type' },
          {
            data: null,
            name: '',
            width: '120px',
            sortable: false,
            searchable: false,
            render(data, type, row) {
              if (row.links.hasOwnProperty('edit')) {
                return `<a class="" href="${row.links.edit}"><i class="fa fa-edit"></i> Edit</a>`;
              }
              return '';
            },
          },
        ],
      });
    }
  }
  if ($('#panel-turnstile-transactions:visible').length) {
    if (!$.fn.dataTable.isDataTable('#panel-turnstile-transactions-table')) {
      $('#panel-turnstile-transactions-table').dataTable({
        ajax: {
          url: $('#panel-turnstile-transactions-table').data('url-table'),
          data: (data) => { },
        },
        columns: [
          { data: 'date', name: 'date' },
          { data: 'input_type', name: 'input_type' },
          {
            data: 'visits',
            name: 'visits',
            render: (data, type, row) => {
              return `<span class="right">${data}</span>`;
            },
          },
          {
            data: 'payment_coin',
            name: 'payment_coin',
            render: (data, type, row) => {
              return `<span class="right">${data}</span>`;
            },
          },
          {
            data: 'payment_contact_less',
            name: 'payment_contact_less',
            render: (data, type, row) => {
              return `<span class="right">${data}</span>`;
            },
          },
          {
            data: 'payment_token',
            name: 'payment_token',
            render: (data, type, row) => {
              return `<span class="right">${data}</span>`;
            },
          },
          {
            data: 'vouchers',
            name: 'vouchers',
            render: (data, type, row) => {
              return `<span class="right">${data}</span>`;
            },
          },
          {
            data: 'servicevisits',
            name: 'servicevisits',
            render: (data, type, row) => {
              return `<span class="right">${data}</span>`;
            },
          },
          {
            data: 'revenue',
            name: 'revenue',
            render: (data, type, row) => {
              return `<span class="right">${data}</span>`;
            },
            searchable: false,
          },
        ],
      });
    }
  }
});

// Add turnstile period
_('#turnstile-period-store').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

// tunstile delete button
_('#turnstile-show-delete').on('click', (e) => {
  e.preventDefault();
  swal({
    title: 'Delete turnstile',
    text: 'Do you really want to delete the turnstile?',
    type: 'warning',
    confirmButtonText: 'Delete',
    showCancelButton: true,
    confirmButtonColor: '#e74c3c',
  }).then((result) => {
    if (result) {
      axios.delete(_(e.target).attr('data-url'))
        .then((response) => {
          makeSwal(response.data);
        });
    }
  });
});
