_(document).ready(() => {
  if (! _('#visitorContainer').length) {
    return;
  }

  /**
   * Create the query string based on the _current_ filter values
   */
  function getFilterQueryString() {
    const form = document.getElementById('filterForm');
    const formData = new FormData(form);

    return new URLSearchParams(formData).toString();
  }

  /**
   * Calculates the amount of days a month has based on the selected period
   *
   * @param period
   */
  function calculateAmountOfDaysInMonth(period) {
    let month = period.split('-')[1];

    if (month.includes(0)) {
      month = month.split('0')[1];
    }

    if ((month % 2 === 1 && month < 8) || (month % 2 === 0 && month >= 8)) {
      return 31;
    }

    if (month === 2) {
      return 28;
    }

    return 30;
  }

  /**
   * Creates the html string for a table row based on the given data
   *
   * @param row
   */
  function formatTableRow(row) {
    const periodDay = calculateAmountOfDaysInMonth(row.period);
    return `<tr>
            <td>${row.number}</td>
            <td><a href="${row.route}">${row.name}</a></td>
            <td>${row.type}</td>
            <td>${row.period}</td>
            <td class="tar" style="padding-right: 10px;><a href="/branches/${row.branch_id}?period_start=${row.period}-01&period_end=${row.period}-${periodDay}#panel-revenue">${row.visits}</a></td>
            <td>
                <span style="text-align: right" class="one-third tar">${row.payment_coin}</span>
                <span class="one-third tar">${row.toilet_price}</span>
                <span class="one-third tar">${row.payment_coin_turnover}</span>
            </td>
            <td>
                <span class="one-third tar">${row.payment_contact_less}</span>
                <span class="one-third tar">${row.toilet_price}</span>
                <span class="one-third tar">${row.payment_contact_less_turnover}</span>
            </td>
            <td>
                <span class="one-third tar">${row.payment_token}</span>
                <span class="one-third tar">${row.toilet_price_token}</span>
                <span class="one-third tar">${row.payment_token_turnover}</span>
            </td>
            <td>
                <span class="one-third tar">${row.payment_manual}</span>
                <span class="one-third tar">${row.toilet_price}</span>
                <span class="one-third tar">${row.payment_manual_turnover}</span>
            </td>
            <td>
                <span class="one-third tar">${row.payment_shower}</span>
                <span class="one-third tar">${row.shower_price}</span>
                <span class="one-third tar">${row.payment_shower_turnover}</span>
            </td>
            <td>
                <span class="one-third tar">${row.voucher}</span>
                <span class="one-third tar">${row.voucher_count}</span>
            </td>
            <td class="tar">${row.total_turnover}</td>
          </tr>`;
  }

  /**
   * Creates the html string for the TOTALS table row based on the given data
   *
   * @param row
   */
  function formatTotalsTableRow(row) {
    return `<tr class="totalRow">
            <td colspan="4">Total:</td>
            <td class="tar" style="padding-right: 10px">${row.visits_total}</td>
            <td>
                <span class="one-third tar">${row.payment_coin_total}</span>
                <span class="one-third tar"></span>
                <span class="one-third tar">${row.payment_coin_turnover_total}</span>
            </td>
            <td>
                <span class="one-third tar">${row.payment_contact_less_total}</span>
                <span class="one-third tar"></span>
                <span class="one-third tar">${row.payment_contact_less_turnover_total}</span>
            </td>
            <td>
                <span class="one-third tar">${row.payment_token_total}</span>
                <span class="one-third tar"></span>
                <span class="one-third tar">${row.payment_token_turnover_total}</span>
            </td>
            <td>
                <span class="one-third tar">${row.payment_manual_total}</span>
                <span class="one-third tar"></span>
                <span class="one-third tar">${row.payment_manual_turnover_total}</span>
            </td>
            <td>
                <span class="one-third tar">${row.servicevisits_total}</span>
                <span class="one-third tar"></span>
                <span class="one-third tar">${row.servicevisits_turnover_total}</span>
            </td>
            <td>
                <span class="one-third tar">${row.payment_voucher_total}</span>
                <span class="one-third tar">${row.payment_voucher_turnover_total}</span>
            </td>
            <td></td>
            <td>${row.total_turnover_total}</td>
          </tr>`;
  }

  /**
   * (Re)Load the table data
   */
  function loadTable() {
    const table = _('#visitorTable');
    let url = table.attr('data-url');

    url += `?${getFilterQueryString()}`;

    // init loader
    table.find('tbody').html('<tr><td colspan="10"><br><div class="loader"><div class="loader-inner ball-pulse"><div></div><div></div><div></div></div></div><br></td></tr>');

    axios.get(url)
      .then((response) => {
        if (response.data.hasOwnProperty('type') && response.data.type === 'error') {
          table.find('tbody').html(`<tr><td colspan="10">${response.data.text}</td></tr>`);
          return;
        }

        let html = '';

        for (let i in response.data.data) {
          html += formatTableRow(response.data.data[i]);
        }

        html += formatTotalsTableRow(response.data.totals);

        table.find('tbody').html(html);
      });
  }

  /**
   * Clone a filter field to be able to filter on more values for that key
   */
  _('[data-clone]').on('click', (e) => {
    e.preventDefault();

    const wrap = _(e.target).parent().find('.clone-wrap');
    const target = _(`.${_(e.target).attr('data-clone')}`)[0];
    const cloneWrap = document.createElement('div');
    cloneWrap.className = 'clone';
    const closeIcon = document.createElement('i');
    closeIcon.className = 'fa fa-close remove-filter-clone-button';
    const clone = target.cloneNode(true);

    cloneWrap.appendChild(clone);
    cloneWrap.appendChild(closeIcon);
    _(wrap).append(cloneWrap);
  });

  _(window).on('click', '.remove-filter-clone-button', (e) => {
    e.preventDefault();

    _(e.target).parent('.clone').remove();
  });

  /**
   * Submit filter form
   */
  _('form').on('submit', (e) => {
    e.preventDefault();

    loadTable();
  });

  /**
   * Export data for _current_ filter values to CSV
   */
  _('.export-btn').on('click', (e) => {
    e.preventDefault();

    const table = _('#visitorTable');
    let url = table.attr('data-download');

    url += `?${getFilterQueryString()}`;

    window.location.href = url;
  });

  /**
   * Inititial load
   */
  function initPage() {
    loadTable();

    flatpickr('input[name=period]', {
      dateFormat: 'Y-m',
    });
  }

  initPage();
});
