import makeSwal from './helpers';

$(() => {
  $.ajaxSetup({
    headers: { 'X-CSRF-TOKEN': window.Atabase.csrfToken },
  });
});


_('#concept-manager-show-delete').on('click', (event) => {
  swal({
    title: 'Delete concept manager',
    text: 'Do you really want to delete the concept manager?',
    type: 'warning',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
    confirmButtonColor: '#e74c3c',
  }).then((result) => {
    if (result) {
      axios.delete(_(event.target).attr('data-url'))
        .then((response) => {
          makeSwal(response.data);
        });
    }
  });
});
