import makeSwal from './helpers';

_(document).ready(() => {
  if (_('#translationShowController').length) {
    _('.ajax-save').on('click', (e) => {
      e.preventDefault();
      const form = _(e.target).closest('form');

      _.ajax({
        type: 'POST',
        url: _(form).attr('href'),
        data: _(form).serialize(),
        success: (response) => {
          makeSwal(response);
        },
        error: (response) => {
          makeSwal(response);
        },
        dataType: 'json',
      });
    });

    return;
  }

  if (!_('#translationController').length) {
    return;
  }

  const urls = {
    table: $('[data-table]').attr('data-table'),
  };

  let dataTableUrl;

  const getDataTableUrl = () => {
    dataTableUrl = `${urls.table}?`;

    $('[data-filter]').each((i, el) => {
      dataTableUrl += `${_(el).attr('data-filter')}=${$(el).find(':selected').attr('value')}`;
      if (i !== ($('[data-filter]').length - 1)) {
        dataTableUrl += '&';
      }
    });

    return dataTableUrl;
  };

  $('[data-table]').dataTable({
    ajax: {
      url: getDataTableUrl(),
    },
    columns: [
      {data: 'id'},
      {data: 'group'},
      {data: 'key'},
      {data: 'value'},
      {data: 'updated_at', width: '190px'},
      {
        data: 'url',
        width: '120px',
        sortable: false,
        searchable: false,
        render: (data, type, row) => {
          return `<a class="table-show-link" href="${data}"><i class="fa fa-search"></i> Details</a>`;
        },
      },
    ],
    order: [[0, 'asc']],
  });

  _('.ajax-post').on('click', (event) => {
    event.preventDefault();

    const button = $(event.target).closest('.ajax-post');
    let title = button.attr('data-swal-title');
    let description = button.attr('data-swal-description');

    if (title === undefined || title.length === 0) {
      title = 'Are you sure?';
    }

    if (description === undefined || description.length === 0) {
      description = 'This action will affect languages and its translations throughout the system.';
    }

    swal({
      title: title,
      text: description,
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    }).then((confirmed) => {
      if (confirmed) {
        $.ajax({
          type: 'POST',
          url: $(event.target).attr('href'),
          data: {},
          success: (response) => {
            makeSwal(response);
          },
          error: (response) => {
            makeSwal(response);
          },
          dataType: 'json',
        });
      }
    });
  });
});
