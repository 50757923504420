import makeSwal from './helpers';
import {toJSON} from "lodash/seq";

// users create
_('#user-create').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

// User delete
_('#user-show-delete').on('submit', (event) => {
  event.preventDefault();
  swal({
    title: 'Delete user',
    text: 'Do you really want to delete the user?',
    type: 'warning',
    confirmButtonText: 'Delete',
    showCancelButton: true,
    confirmButtonColor: '#e74c3c',
  }).then((result) => {
    if (result) {
      axios.delete(_(event.target).attr('action'))
        .then((response) => {
          makeSwal(response.data);
        });
    }
  });
});

// User update
_('#user-update').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

// Roles update
_('#roles-update').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#save-roles').on('click', (e) => {
  const userID = $('#save-roles').data('user-id');

  $('.branch-input').each(function() {
    const branchID = $(this).attr('id');

    const isChecked = $(this).prop('checked'); // Check if the current checkbox is checked

    if (isChecked) {
      axios.post(`/branches/${branchID}/users/attach/${userID}?refresh=0`);
    } else {
      axios.delete(`/branches/${branchID}/users/detach/${userID}?refresh=0`);
    }
  });

  makeSwal({
    title: 'Success',
    text: 'Changes saved!',
    type: 'success',
    reload: true,
  });
});

$('#branch-all').change(function() {
  var isChecked = $(this).is(':checked');
  $('.branch-input').prop('checked', isChecked);
});

// MFA
$(() => {
  $.ajaxSetup({
    headers: {'X-CSRF-TOKEN': window.Atabase.csrfToken},
  });

  $('[mfa-setup]').click((e) => {
    const $url = $(e.target).attr('mfa-setup');
    $.get($url, (data) => {
      swal({
        title: 'Multi-Factor Authentication',
        input: 'text',
        inputAttributes: {
          maxlength: 16,
          autocapitalize: 'on',
          autocorrect: 'off',
        },
        inputPlaceholder: 'Security code...',
        html: data,
        showCancelButton: true,
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        inputValidator: value => new Promise((resolve, reject) => {
          $.post($url, {mfa_key: value}, 'json')
            .done((data, response) => {
              if (data.type === 'error') {
                reject(data.message);
              }

              resolve();
            });
        }),
      }).then((value) => {
        swal({
          type: 'success',
          title: 'Success',
          html: 'The MFA token was added to the account.',
        });
      });
    }, 'html');
  });
});

// get user branches table
_(document).ready(() => {
  if (!$.fn.dataTable.isDataTable('#users-branches-show')) {
    $('#users-branches-show').dataTable({
      order: [[4, 'asc']],
      ajax: {
        url: $('#users-branches-show').data('url-table'),
        data(data) {
        },
      },
      columns: [
        {data: 'id', width: '80px'},
        {data: 'name'},
        {data: 'code'},
        {data: 'country.name'},
        {
          data: null, render: function (data, type, row) {
            if (!row.branch_type) {
              return '';
            }

            return row.branch_type.name;
          },
        },
        {
          data: null, name: '', width: '120px', sortable: false, searchable: false, render: function (data, type, row) {
            return '<a class="table-show-link" href="' + $('#users-branches-show').data('url-detail').replace('_id_', row.id) + '"><i class="fa fa-search"></i> Details</a>';
          }
        },
      ]
    });
  }
});
