/* eslint-disable */
import swal from 'sweetalert2/dist/sweetalert2.all.min.js';
// import Choices from 'choices.js/assets/scripts/dist/choices.js';
import Choices from 'choices.js/assets/scripts/dist/choices.js';
import flatpickr from 'flatpickr';
import initQuestionaire from './admin/checklists/questionaire';
import initReceipt from "./admin/receipts/receipts";
import initReceiptShopManager from "./admin/receipts/receipts-shop-manager";
require ('foundation-sites/js/foundation.core.js');
require ('foundation-sites/js/foundation.util.mediaQuery.js');
require ('foundation-sites/js/foundation.accordion.js');

/**
 * First we will load all of this project's JavaScript dependencies.
 * It is a great starting point when building robust,
 * powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
/**
 * We'll load standard libraries which provide support for standard
 * application features. This code may be modified to fit
 * the specific needs of your application.
 */

window.$ = require('jquery');
window.jQuery = require('jquery');
window.moment = require('moment');

window.$(document).foundation();
window.swal = swal;
window.Choices = Choices;

require('datatables.net');
require('datatables.net-select');
require('datatables.net-responsive');
require('./admin/deposit');
require('./admin/branches');
require('./admin/documents');
require('./admin/branches/revenue');
require('./admin/branches/turnstile_update');
require('./admin/turnstiles');
require('./admin/portal-turnstiles');
require('./admin/turnstile-days');
require('./admin/login');
require('./admin/debtors');
require('./admin/users');
require('./admin/countries');
require('./admin/country_ledgers');
require('./admin/roles');
require('./admin/entities');
require('./admin/invoices');
require('./admin/audit_logs');
require('./admin/seal_bag');
require('./admin/money-transfers');
require('./admin/downloads/seal-bags');
require('./admin/checklists');
require('./admin/checklists/questions');
require('./admin/receipts/receipts-shop-manager');
require('./admin/receipts/receipts');
require('./admin/receipts_table');
require('./admin/visitors');
require('./admin/visitors_turnover');
require('./admin/translations');
require('./admin/turnstile_monthly');
require('./admin/news');
require('./admin/uninvoiced');
require('./admin/conceptManager');

/**
 * We'll register a HTTP interceptor to attach the "CSRF" header to each of
 * the outgoing requests issued by this application. The CSRF middleware
 * included with Laravel will automatically verify the header's value.
 */

window.$.ajaxSetup({
  headers: { 'X-CSRF-TOKEN': Atabase.csrfToken },
});

$.extend(true, $.fn.dataTable.defaults, {
  dom: '<"elementPanel"<"title"<"left"f><"right"B>><"content"t><"footer" <"left"l><"left"i><"right"p>>>',
  responsive: true,
  sServerMethod: 'POST',
  searchDelay: 750,
  lengthMenu: [10, 25, 50, 75, 100, 200, 500, 1000],
  iDisplayLength: 50,
  deferRender: false,
  processing: true,
  serverSide: true,
  paging: true,
  searching: true,
  ordering: true,
  stateSave: true,
  language: {
    searchPlaceholder: 'Search...',
    sSearch: '',
    sInfo: 'Showing _START_ to _END_ of _TOTAL_ entries',
    sInfoFiltered: '',
  },
});

if (document.getElementById('logout')) {
  document.getElementById('logout').addEventListener('click', (e) => {
    e.preventDefault();
    swal({
      title: 'Peace Out!',
      text: 'The world will now be a safer place.',
      imageUrl: '/assets/images/cartman.png',
      showCancelButton: true,
      confirmButtonText: 'Logout',
    }).then(() => {
      document.getElementById('logout-form').submit();
    });
  });
}
if (document.getElementById('questionaireForm')) {
  initQuestionaire();
}

if (_('#receiptShopManager').length) {
  initReceiptShopManager();
} else if (window.location.pathname === '/receipts/create' || window.location.pathname.match(/\/receipts\/.+/)) {
  initReceipt();
}

_('.dataTable').on('click', 'tbody', (elem) => {
  if (_(elem.target).parent().find('.cancel-redirect').length >= 1) {
    return;
  }

  if (_('.table-show-link').length) {
    if (_(elem.target).parent().children().find("a.table-show-link").attr('href') != null)
      document.location = _(elem.target).parent().children().find("a.table-show-link").attr('href');
  }
});

$('input[type=search]').on('focus', function(){
  // replace CSS font-size with 16px to disable auto zoom on iOS
  $(this).data('fontSize', $(this).css('font-size')).css('font-size', '16px');
}).on('blur', function(){
  // put back the CSS font-size
  $(this).css('font-size', $(this).data('fontSize'));
});

window.makeSwalValidation = (axiosError) => {
  if(!axiosError.response){
    return console.log(axiosError);
  }

  if(axiosError.response.status && axiosError.response.status !== 422){
    return swal('Error', 'Something went wrong', 'error');
  }

  if(axiosError.response.data){
    let concreteError = '';

    const errorsKeys = Object.keys(axiosError.response.data.errors);
    for(let index in axiosError.response.data.errors) {
      if (index !== 'preserved_image') {
        concreteError += index + ' ' + axiosError.response.data.errors[index][0] + '<br>';

        if(_('input[name="'+index+'"]').length){
          _('input[name="'+index+'"]').addClass('validation-error');
        }
        if(_('select[name="'+index+'"].choices').length){
          _('select[name="'+index+'"].choices').parent().addClass('validation-error');
        }
      }
    }

    return swal('Warning', concreteError, 'warning');
  }
};
