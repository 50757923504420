import makeSwal from './helpers';

$(() => {
  $.ajaxSetup({
    headers: { 'X-CSRF-TOKEN': window.Atabase.csrfToken },
  });
});

_(document).ready(() => {
  if (!$.fn.dataTable.isDataTable('#documents-index-table')) {
    $('#documents-index-table').dataTable({
        bFilter: false, bInfo: false,
      ajax: {
        url: $('#documents-index-table').data('url-table'),
        data: (data) => { },
      },
      columns: [
        { data: 'file_name', sortable: false },
        { data: 'date', sortable: false },
        { data: null, width: '160px', render: function(data, type, row) {
          let html = '';

          if (row.links.download.length) {
            html += '<a class="button secondary radius small left" style="margin-right: 10px;" href="' + row.links.download + '"><i class="fa fa-download"></i></a>';
          }

          if (row.links.edit.length) {
            html += '<a class="button secondary warning radius small left" href="' + row.links.edit + '"><i class="fa fa-pencil-square-o"></i></a>';
          }

        return html;
        }},
      ],
    });
  }
});


// add a document
_('#documents-store').on('submit', (e) => {
  e.preventDefault();
  const data = new FormData(e.target);
  axios.post(_(e.target).attr('action'), data)
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

// update a document
_('#document-update').on('submit', (event) => {
  event.preventDefault();
  swal({
    title: 'Document wijzigen',
    text: _('#document-update').attr('data-confirm-text'),
    type: 'question',
    confirmButtonText: _('#document-update').attr('data-confirm-button-text'),
    showCancelButton: true,
    confirmButtonColor: '#2eba62',
    cancelButtonText: _('#document-update').attr('data-cancel-button-text'),
  }).then((result) => {
    if (result) {
      axios
      .post(_('#document-update').attr('action'), {
        _method: 'PUT',
        _token: _('[name=_token]').val(),
        role: _('select[name=role_id]').val(),
      })
      .then((response) => {
        makeSwal(response.data);
      });
    }
  });
});

// delete a document
_('#document-delete').on('click', (event) => {
  event.preventDefault();
  swal({
    title: 'Delete',
    text: 'Do you really want to delete the document?',
    type: 'warning',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
    confirmButtonColor: '#e74c3c',
  }).then((result) => {
    if (result) {
      axios
      .delete(_('#document-delete').attr('action'))
      .then((response) => {
        makeSwal(response.data);
      });
    }
  });
});
