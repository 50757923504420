export default function makeSwal(params) {
  swal({
    title: params.title,
    text: params.text,
    type: params.type,
  }).then(() => {
    if (params.reload) {
      window.location.reload();
    } else if (params.redirect) {
      window.location = params.redirect;
    }
  });
}
