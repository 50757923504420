import makeSwal from './helpers';

_(document).ready(() => {
  // add a sealBag
  $(() => {
    $.ajaxSetup({
      headers: { 'X-CSRF-TOKEN': window.Atabase.csrfToken },
    });
  });

  // update a sealBag
  _('#seal-bag-update').on('submit', (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    axios.post(_(e.target).attr('action'), data)
      .then((response) => {
        makeSwal(response.data);
      })
      .catch((axiosError) => {
        makeSwalValidation(axiosError);
      });
  });

  // delete a sealBag
  _('#seal-bag-delete').on('click', (event) => {
    swal({
      title: 'Delete',
      text: 'Do you really want delete the seal bag?',
      type: 'warning',
      confirmButtonText: 'Confirm',
      showCancelButton: true,
      confirmButtonColor: '#e74c3c',
    }).then((result) => {
      if (result) {
        axios.delete(_(event.target).attr('data-url'))
          .then((response) => {
            makeSwal(response.data);
          });
      }
    });
  });

  // lock/unlock a sealBag
  _('#seal-bag-lock').on('click', (event) => {
    swal({
      title: _('#seal-bag-lock').attr('data-action'),
      text: `Do you really want ${_('#seal-bag-lock').attr('data-action')} the seal bag?`,
      type: 'warning',
      confirmButtonText: 'Confirm',
      showCancelButton: true,
      confirmButtonColor: '#e74c3c',
    }).then((result) => {
      if (result) {
        axios.post(_(event.target).attr('data-url'))
          .then((response) => {
            makeSwal(response.data);
          });
      }
    });
  });

  if (!$.fn.dataTable.isDataTable('#seal-bags-index-table')) {
    $('#seal-bags-index-table').dataTable({
      ajax: {
        url: $('#seal-bags-index-table').attr('data-url-table'),
        data: (data) => {
          if (! data.hasOwnProperty('filters')) data.filters = {};

          data.filters.country = $('#seal-bags-index-table-filter-country').val();
        },
      },
      columns: [
        { data: 'created_at' },
        { data: 'branch.name', name: 'branches.name', searchable: false },
        { data: 'number', className: 'tar' },
        { data: 'amount', className: 'tar' },
        { data: 'user.name', name: 'users.first_name', searchable: false },
        { data: 'country.name', name: 'countries.name' },
        { data: 'locked', sortable: false, render: (data) => {
            if (data == 1) {
              return `<span class="label success round">Yes</span>`;
            }
            return `<span class="label warning round">No</span>`;
          } },
        { data: null,
          width: '120px',
          sortable: false,
          searchable: false,
          render: (data, type, row) => `<a class="table-show-link" href="${$('#seal-bags-index-table').attr('data-url-detail').replace('_id_', row.uuid)}"><i class="fa fa-search"></i> Details</a>` },
      ],
      order: [[1, 'desc']],
    });
  }

  $('#seal-bags-index-table-filter-country').change(function () {
    $('#seal-bags-index-table').dataTable().api().draw();
  });

  $('.close').click(() => {
    $('#sealbag-locked-alert').css('display', 'none');
  });
});
