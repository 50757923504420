_(document).ready(() => {
  if (!$.fn.dataTable.isDataTable('#receipts-index-table')) {
    $('#filter-button').on('click', function(e) {
      e.preventDefault();
      var branch = $('#branch').val();
      var country = $('#country').val();
      $('#receipts-index-table').DataTable().ajax.url($('#receipts-index-table').attr('data-url-table') + '?branch=' + branch + '&country=' + country).draw();

    });

    $('#receipts-index-table').dataTable({
      ajax: {
        url: $('#receipts-index-table').attr('data-url-table'),
        data: function(data) {
          var urlParams = new URLSearchParams(window.location.search);
          var branch = urlParams.get('branch');
          var country = urlParams.get('country');

          if (branch) {
            console.log(branch);
            data.branch = branch;
          }

          if (country) {
            console.log(country);
            data.country = country;
          }
        }
      },
      order: [[0, 'desc']],
      columns: [
        { data: 'created_at' },
        { data: 'branch' },
        { data: 'user' },
        { data: 'country' },
        { data: 'amount', className: 'tar' },
        {
          data: 'image_path',
          width: '120px',
          sortable: false,
          searchable: false,
          render: data => `<a id="popup__button" class="cancel-redirect" data="${data}"><i class="fa fa-file-image-o"></i> Foto</a>`,
        },
        {
          data: null,
          width: '120px',
          sortable: false,
          searchable: false,
          render: (data, type, row) => `<a class="table-show-link" href="${$('#receipts-index-table').attr('data-url-detail').replace('_id_', row.id)}"><i class="fa fa-search"></i> Details</a>`
        },
      ],
    });
  }

  if (!$.fn.dataTable.isDataTable('#receipts-seal-bag-table')) {
    $('#receipts-seal-bag-table').dataTable({
      ajax: {
        url: $('#receipts-seal-bag-table').attr('data-url-table'),
      },
      columns: [
        { data: 'number' },
        { data: 'locked',
          render: (data) => {
            if (data === 1) {
              return '<span class="label success round">Yes</span>';
            }
            return '<span class="label warning round">No</span>';
          },
        },
        { data: 'amount', className: 'tar' },
        { data: 'created_at' },
        {
          data: null,
          width: '120px',
          sortable: false,
          searchable: false,
          render: (data, type, row) => `<a class="table-show-link" href="${$('#receipts-seal-bag-table').attr('data-url-detail').replace('_id_', row.uuid)}"><i class="fa fa-search"></i> Details</a>`
        },
      ],
    });
  }

  // Open popup
  if ($('#popup').length && $('#popup__button').length) {
    setTimeout(() => {
      const modal = document.getElementById('popup');
      const btn = document.getElementById('popup__button');
      const imageUrl = btn.getAttribute('data');
      const span = document.getElementsByClassName('close')[0];
      btn.onclick = () => {
        modal.style.display = 'block';
        document.getElementById('image').src = imageUrl;
      };

      span.onclick = () => {
        modal.style.display = 'none';
      };

      window.onclick = (event) => {
        if (event.target === modal) {
          modal.style.display = 'none';
        }
      };
    }, 1000);
  }
});
