import makeSwal from '../helpers';

_(document).ready(() => {
  if (!$.fn.dataTable.isDataTable('#download-seal-bags-index')) {
    $('#download-seal-bags-index-table').dataTable({
      ajax: {
        url: $('#download-seal-bags-index-table').data('url-table'),
        data: (data) => {
        },
      },
      select: {
        style: 'multi',
        selector: 'td:first-child',
      },
      columnDefs: [{
        orderable: false,
        className: 'select-checkbox',
        targets: 0,
      }],
      scrollY: 400,
      scrollCollapse: true,
      columns: [
        {
          data: null,
          width: '10%',
          name: 'checkbox',
          sortable: false,
          searchable: false,
          render: (data, type, row) => '',
        },
        { data: 'location' },
        { data: 'created_at' },
        { data: 'period' },
        { data: 'total_amount',
          sortable: false,
          render: (data, type, row) => '<span class="right">' + data + '</span>'},
        { data: null,
          id: 'details_button',
          width: '120px',
          sortable: false,
          searchable: false,
          render(data, type, row) {
            return `<a href="${$('#download-seal-bags-index-table').data('url-detail').replace('_id_', row.id)}"><i class="fa fa-search"></i> Details</a>`;
          } },
      ],
    });
  }

  if (!$.fn.dataTable.isDataTable('#download-seal-bag-overview-table')) {
    $('#download-seal-bag-overview-table').dataTable({
      order: [[1, 'asc']],
      ajax: {
        url: $('#download-seal-bag-overview-table').data('urlTable'),
        data: (data) => { },
      },
      columns: [
        { data: 'id' },
        { data: 'user.name' },
        { data: 'created_at' },
        { data: null,
          width: '200px',
          sortable: false,
          searchable: false,
          render: (data, type, row) => {
            if (row.document_path) {
              return `<a class="table-show-link" href="${row.document_path}"><i class="fa fa-cloud-download" aria-hidden="true"></i> Download</a>`;
            }
            return '<i class="fa fa-chain-broken" aria-hidden="true"></i>';
          } },
      ],
    });
  }
});

_('#seal-bag-export-button').on('click', (e) => {
  const table = $('#download-seal-bags-index-table').dataTable().api();
  const sealBagsIds = [];
  const depositIds = [];

  table.rows({ selected: true }).every((index) => {
    const row = table.row(index);
    sealBagsIds.push(row.data().id);
    depositIds.push(row.id);
  });

  axios.post(_(e.target).attr('data-download-url'), {
    sealBagsIds,
    deposits_ids: depositIds,
  })
  .then((response) => {
    makeSwal(response.data.swal);
    if (response.data.xml_temporary_url) {
      // Let's make Stefano angry
      const link = document.createElement('a');
      link.download = name;
      link.href = response.data.xml_temporary_url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
});
