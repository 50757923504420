import makeSwal from './helpers';

_(document).ready(() => {
  if (!$.fn.dataTable.isDataTable('#invoices-index-table')) {
    $('#invoices-index-table').dataTable({
      ajax: {
        url: $('#invoices-index-table').data('url-table'),
        data: (data) => {},
      },
      select: {
        style: 'multi',
        selector: 'td:first-child',
      },
      bPaginate: true,
      columnDefs: [{
        orderable: false,
        className: 'select-checkbox',
        targets: 0,
      }],
      columns: [
        { data: null,
          width: '10%',
          name: 'checkbox',
          sortable: false,
          searchable: false,
          render: (data, type, row) => '' },
        { data: 'created_at' },
        { data: 'debtor.name', name: 'debtors.name' },
        { data: 'period' },
        {
          data: 'branches.count',
          searchable: false,
          render: (data, type, row) => '<span class="right">' + data + '</span>'},
        {
          data: 'total',
          searchable: false,
          render: (data, type, row) => '<span class="right">' + data + '</span>'},
      ],
    });
  }

  let month = new Date();
  month = month.setMonth(month.getMonth() - 1);
  const monthField = flatpickr('#month', {
    dateFormat: 'Y-m',
    maxDate: month,
    onChange: (selectedDates, dateStr, instance) => {
      if ($.fn.dataTable.isDataTable('#invoice-create-branch-table')) {
        $('#invoice-create-branch-table').dataTable().api().destroy();
      }
      $('#invoice-create-branch-table').dataTable({
        ajax: {
          url: $('#invoice-create-branch-table').data('url-table'),
          data: (data) => {
            data.start_at = dateStr;
          },
        },
        bPaginate: false,
        dom: '<table>',
        columns: [
          { data: null,
            width: '10%',
            name: 'select',
            sortable: false,
            searchable: false,
            render: (data, type, row) => `<input type="checkbox" name="branch_ids[]" value="${row.id}" checked>` },
          { data: 'debtor.name', name: 'debtors.name' },
          { data: 'name' },
          { data: 'code' },
          { data: 'country.name' },
          { data: 'last_invoiced_at' },
          { data: 'last_invoiced_period' },
        ],
      });
    },
  });
});

_('#select-all').on('click', () => {
  $('#select-all').click(function () {
    $('input[type=checkbox]').prop('checked', $(this).prop('checked'));
  });
});

_('#invoices-store').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data.swal);

      if (response.data.swal.type === 'success') {
          // Let's make Stefano angry
          const link = document.createElement('a');
          link.download = name;
          link.href = response.data.xml_temporary_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
    })
    .catch((axiosError) => {
      console.log(axiosError);
    });
});

_('#invoices-download-xml').on('click', (e) => {
  const table = $('#invoices-index-table').dataTable().api();
  const invoiceIds = [];

  table.rows({ selected: true }).every((index) => {
    const row = table.row(index);

    invoiceIds.push(row.data().id);
  });

  if (invoiceIds.length === 0) {
    swal({
      title: "No invoice(s) selected",
      type: "warning",
      text: "Please select at least 1 invoice to download."
    });

    return;
  }

  axios.post(_(e.target).attr('data-download-url'), {
    invoiceIds,
  })
    .then((response) => {
      makeSwal(response.data.swal);

      if (response.data.swal.type === 'success') {
          // Let's make Stefano angry
          const link = document.createElement('a');
          link.download = name;
          link.href = response.data.xml_temporary_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
    });
});
