import makeSwal from "../helpers";

_(document).ready(() => {

  // Show or hide the input depending on the question answer type
  if (_('#answer_type_id').length && 'Yes/No' === _('#answer_type_id').text()) {
    _('.answer-type-translation').attr('type', 'text');
  }

  _('#answer_type_id').on('change', (elem) => {
    if ('Yes/No' === _(elem.target).text()){
      _('.answer-type-translation').attr('type', 'text');
    } else {
      _('.answer-type-translation').attr('type', 'hidden');
      _('.answer-type-translation').attr('value', '');
    }
  });
  // end


  // update a question
  _('#question-update').on('submit', (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    axios.post(_(e.target).attr('action'), data)
      .then((response) => {
        makeSwal(response.data);
      })
      .catch((axiosError) => {
        makeSwalValidation(axiosError);
      });
  });
  // end the update

  // update a question
  _('#question-create').on('submit', (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    axios.post(_(e.target).attr('action'), data)
      .then((response) => {
        makeSwal(response.data);
      })
      .catch((axiosError) => {
        makeSwalValidation(axiosError);
      });
  });
  // end the update

  // delete a question
  _('#question-delete').on('click', (event) => {
    swal({
      title: 'Delete question',
      text: 'Do you really want to delete the question?',
      type: 'warning',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      confirmButtonColor: '#e74c3c',
    }).then((result) => {
      if (result) {
        axios.delete(_(event.target).attr('data-url'))
          .then((response) => {
            makeSwal(response.data);
          });
      }
    });
  });
  // end delete
});
