import makeSwal from './helpers';

const genericInputs = [
  'Debtor', 'Branch', 'Branchstatus', 'Turnstile', 'User',
  'Role', 'Invoice', 'Receipt', 'System', 'SealBagExport', 'Deposit', 'Checklist',
];

_(document).ready(() => {
  if (!$.fn.dataTable.isDataTable('#roles-index')) {
    $('#roles-index').dataTable({
      order: [[1, 'asc']],
      ajax: {
        url: $('#roles-index').data('urlTable'),
        data: (data) => { },
      },
      columns: [
        { data: 'id', width: '80px' },
        { data: 'name' },
        { data: 'description' },
        { data: 'created_at' },
        { data: null,
          width: '120px',
          sortable: false,
          searchable: false,
          render: (data, type, row) => `<a class="table-show-link" href="${$('#roles-index').data('urlDetail').replace('_id_', row.id)}"><i class="fa fa-search"></i> Details</a>` },
      ],
    });
  }
});

// role create
_('#role-create').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

// role update
_('#role-update').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#role-delete').on('click', (event) => {
  swal({
    title: 'Delete',
    text: 'Do you really want to delete the role?',
    type: 'warning',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
    confirmButtonColor: '#e74c3c',
  }).then((result) => {
    if (result) {
      axios.delete(_(event.target).attr('data-url'))
        .then((response) => {
          makeSwal(response.data);
        });
    }
  });
});

// Generic checkbox in roles create to check other checkboxes

genericInputs.forEach((element) => {
  $(`input[name='${element}-generic']`).on('click', () => {
    if ($(`input[name='${element}-generic']`).prop('checked') === true) {
      $(`input[name='${element}[]']`).prop('checked', true);
    } else {
      $(`input[name='${element}[]']`).prop('checked', false);
    }
  });
});
