_('.turnstileMonthForm').on('submit', (e) => {
  e.preventDefault();

  const form = $(e.target);
  const url = $(form).attr('action');
  const data = $(form).serialize();

  axios.post(url, data)
    .then(() => {
      window.location = window.location.href;
    });
});

_('#filter-manual').on('click', (e) => {
  const form = document.getElementById('filterForm');
  const formData = new FormData(form);

  const newUrl= new URLSearchParams(formData).toString();

  window.location = `${window.location.href}?${newUrl}`;
});

