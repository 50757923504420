export default class Questionaire {
  constructor(questions, branchId) {
    this.id = null;
    this.questions = questions;
    this.averages = [];
    this.currentCategory = 0;
    this.currentQuestion = 0;
    this.branch_id = branchId;
    this.openSummary = undefined;
    this.summaryActive = false;
  }

  update(value) {
    this.questions[this.currentCategory].questions[this.currentQuestion].value = value;
  }

  updateForAverage(value) {
    if (!this.averages.hasOwnProperty(this.currentCategory)) {
      this.averages[this.currentCategory] = [];
    }

    this.averages[this.currentCategory][this.currentQuestion] = parseInt(value, 10);
  }

  getAverageForCategory() {
    if (!this.averages.hasOwnProperty(this.currentCategory)) {
      return '-';
    }

    const answers = this.averages[this.currentCategory];
    let average = 0;

    for (let i in answers) {
      average += answers[i];
    }

    if (average === 0) {
      return '-';
    }

    return Math.round(((average / Object.keys(answers).length) * 10)) / 10;
  }

  getAverageForAllCategories() {
    if (!this.averages.length) {
      return null;
    }

    let average = 0;
    let amountOfAnswers = 0;
    for (let j in this.averages) {
      let answers = this.averages[j];

      for (let i in answers) {
        amountOfAnswers += 1;
        average += answers[i];
      }
    }

    if (average === 0) {
      return null;
    }

    return Math.round(((average / amountOfAnswers) * 10)) / 10;
  }

  goToSummary() {
    this.openSummary = this.currentCategory;
    this.summaryActive = true;
  }

  goToNextQuestion() {
    this.currentQuestion += 1;

    if (this.currentQuestion >= this.questions[this.currentCategory].questions.length) {
      if (!this.summaryActive) {
        if ((Object.keys(this.questions).length - 2) > this.currentCategory) {
          this.goToSummary();
        } else {
          this.summaryActive = false;
          this.goToNextCategory();
        }
      } else {
        this.summaryActive = false;
        this.goToNextCategory();
      }
    }
  }

  goToPreviousQuestion() {
    this.currentQuestion -= 1;

    if (this.summaryActive) {
      this.summaryActive = false;
    }

    if (this.currentQuestion < 0) {
      this.goToPreviousCategory();
    }
  }

  goToNextCategory() {
    this.currentQuestion = 0;
    this.currentCategory += 1;
  }

  goToPreviousCategory() {
    this.currentCategory -= 1;
    this.currentQuestion = this.questions[this.currentCategory].questions.length - 1;
  }

  set categoryIndex(index) {
    this.currentCategory = index;
  }

  get categoryIndex() {
    return this.currentCategory;
  }

  set questionIndex(index) {
    this.currentQuestion = index;
  }

  get questionIndex() {
    return this.currentQuestion;
  }

  set countCategories(amount) {
    this.categoryCount = amount;
  }

  get countCategories() {
    return this.categoryCount;
  }

  set countQuestions(amount) {
    this.questionCount = amount;
  }

  get countQuestions() {
    return this.questionCount;
  }

  get questionaire() {
    return this.questions;
  }

  get questionaireId() {
    return this.id;
  }

  set questionaireId(value) {
    this.id = value;
  }

  set branchId(value) {
    this.branch_id = value;
  }

  get branchId() {
    return this.branch_id;
  }
}
