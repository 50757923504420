import makeSwal from '../helpers';
import Receipt from '../receipts/receipt.class';

const globalReceipt = new Receipt();

function doInputSwal(url) {
  const html =
    '<table class="fullWidth">' +
    '<tbody>' +
    '<tr>' +
    '<td>Number</td>' +
    '<td><input name="seal_bag_number" id="seal_bag_number" type="number" step="1"></td>' +
    '</tr>' +
    '<tr>' +
    '<td>Amount</td>' +
    '<td><input name="seal_bag_amount" id="seal_bag_amount" type="number" step="0.01" pattern="[0-9.]+"></td>' +
    '</tr>' +
    '</tbody>' +
    '</table>';
  swal({
    title: 'Add new seal bag',
    type: 'info',
    showCancelButton: true,
    confirmButtonText: 'Add',
    html,
    preConfirm() {
      return new Promise((resolve, reject) => {
        if (document.getElementById('seal_bag_number').value && document.getElementById('seal_bag_amount').value) {
          const values = {
            seal_bag_number: document.getElementById('seal_bag_number').value,
            seal_bag_amount: document.getElementById('seal_bag_amount').value,
          };
          resolve(values);
        } else {
          reject('All fields are required');
        }
      });
    },
  }).then((result) => {
    axios.post(_('#add-seal-bag-button').attr('data-url-add-seal-bag'), result)
      .then((response) => {
        makeSwal(response.data);
      })
      .catch((axiosError) => {
        const error = Object.values(axiosError.response.data.errors)[0][0];
        const response = {
          title: error,
          type: 'error',
        };

        makeSwal(response);
      });
  });
}

function showSwal(message, type) {
  makeSwal({
    title: type.toUpperCase(),
    text: message,
    type,
  });
}

function buildSealBagObject(receiptId = null) {
  return {
    number: _('#seal-bag-number').val(),
    amount: _('#seal-bag-amount').val(),
    receipt_id: receiptId,
  };
}

/**
 * @returns object
 */
function printSealBag(index) {
  _('#seal-bag-number').parent().parent().before(
    '<tr valign="top">' +
      `<td><input type="text" class="seal-bag-number" data-index="${index}" name="sealBags[${index}][number]" value="${_('#seal-bag-number').val()}"></td>` +
      `<td><input type="text" class="seal-bag-amount ${_('#error-message').attr('data-error') ? 'hasError' : ''}" data-index="${index}" name="sealBags[${index}][amount]" value="${_('#seal-bag-amount').val()}"><span class="errorMessage">${_('#error-message').attr('data-error') ? _('#error-message').attr('data-error') : ''}</span></td>` +
      `<td>
        <a class="button radius settings small secondary delete-seal-bag" data-seal-bag-number="${_('#seal-bag-number').val()}">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </a>
      </td>
    </tr>`);

  _('.delete-seal-bag').on('click', (event) => {
    event.preventDefault();
    let target = event.target;
    if (_(event.target).hasClass('fa')) {
      target = _(event.target).closest('.button');
    }

    _(target).parent().parent().remove();
    globalReceipt.deleteSealBag(_(target).attr('data-seal-bag-number'));
    saveToLocalStorage(globalReceipt);
  });

  _('#seal-bag-number').val('');
  _('#seal-bag-amount').val('');
}

function printSealBagFromStorage(LocalReceipt) {
  LocalReceipt.sealBags.forEach((sealBag) => {
    const index = globalReceipt.addSealBag(sealBag);
    const amount = sealBag.amount.replace(/,/g, '').slice(0, -2);
    const showError = amount > 1000;

    _('#seal-bag-number').parent().parent().before(
      '<tr valign="top">' +
        `<td><input type="text" class="seal-bag-number" data-index="${index}" name="sealBags[${index}][number]" value="${sealBag.number}">
        </td>` +
        `<td><input type="text" class="seal-bag-amount ${showError ? 'hasError' : ''}" data-index="${index}" name="sealBags[${index}][amount]" value="${sealBag.amount}"><span class="errorMessage">${showError ? _('#error-message').attr('data-error-prefilled') : ''}</span></td>` +
        `<td>
          <a class="button radius settings small secondary delete-seal-bag" data-seal-bag-number="${sealBag.number}">
             <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
        </td>
      </tr>`);

    _('.delete-seal-bag').on('click', (event) => {
      event.preventDefault();
      let target = event.target;
      if (_(event.target).hasClass('fa')) {
        target = _(event.target).closest('.button');
      }
      _(target).parent().parent().remove();
      globalReceipt.deleteSealBag(_(target).attr('data-seal-bag-number'));
      saveToLocalStorage(globalReceipt);
    });
  })

  _('#seal-bag-number').val('');
  _('#seal-bag-amount').val('');
}

function getAttributes() {
  return {
    branch: _('#branch_id').val(),
    date: _('#date').val(),
    notes: _('#notes').val(),
  };
}

function showSealBagsInputs() {
  _('#select-seal-bags').removeClass('hide');
  _('#add-seal-bags-button').addClass('hide');
}

function validateValues() {
  if (_('.seal-bag-number').length) {
    for (const index in _('.seal-bag-number')) {
      if (!isNaN(index)) {
        if (!(_(_('.seal-bag-number')[index]).val() && _(_('.seal-bag-amount')[index]).val())) {
          return false;
        }
      }
    }
  } else if (_('#seal-bag-number').length) {
    if (!(_('#seal-bag-number').val() && _('#seal-bag-amount').val())) {
      return false;
    }
  }

  if (_('#date').val()) {
    return true;
  }

  return false;
}

/**
 * Save the receipt to the DB
 * @param receipt
 * @param url
 * @param sealBags
 * @returns {boolean}
 */
function saveReceipt(receipt, url) {
  const data = new FormData(receipt.target);
  axios.post(url, data)
    .then((response) => {
      makeSwal(response.data);
      localStorage.clear();
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
}

function dealBoonCase(event) {
  if (_('#previous_cumulative').length) {
    if (_('#previous_cumulative').val() === _('#previous_cumulative_check').val()) {
      saveReceipt(event, _(event.target).attr('action'));
    } else {
      swal({
        title: 'Warning',
        text: 'The cumulative value of the previous receipt does not match the cumulative check value, do you want to continue?',
        type: 'warning',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        confirmButtonColor: '#2eba62',
        cancelButtonColor: '#e74c3c',
      }).then((result) => {
        if (result) {
          saveReceipt(event, _(event.target).attr('action'));
        }
      });
    }
  } else {
    saveReceipt(event, _(event.target).attr('action'));
  }
}

function dealOtherCase(event) {
  saveReceipt(event, _(event.target).attr('action'));
}

function initReceiptCreate() {
  function validateSealBagEntry(type, branch, sealBag, sealBagNumbers)
  {
    axios.post('/seal-bags/validate-number', {
      branch: branch,
      seal_bag: sealBag,
      seal_bags_added: sealBagNumbers,
    }).then((response) => {
      if (response.data.swal) {
        swal({
          title: 'Notice',
          type: response.data.swal.type,
          text: response.data.swal.text,
          showCancelButton: true,
          cancelButtonText: 'Change number',
          confirmButtonText: 'Add seal bag',
        }).then(() => {
          if (type === 'create') {
            _('#add-seal-bags-table-button').trigger('click');
          } else if (type === 'update') {
            // omitted on purpose
          }
        }).catch(() => {
          _('#seal-bag-number')[0].value = '';
        });
      } else if (type === 'create') {
        _('#add-seal-bags-table-button').trigger('click');
      } else if (type === 'update') {
        // omitted on purpose
      }
    });
  }

  // Create seal bag row validation
  _('#seal-bag-number, #seal-bag-amount').on('change', () => {
    if (_('#seal-bag-number').val() && _('#seal-bag-amount').val()) {
      const sealBagNumbers = [];
      if (_('.seal-bag-number').length) {
        for (let index = 0; index < _('.seal-bag-number').length; index++) {
          const element = _('.seal-bag-number')[index];
          sealBagNumbers.push(element.value);
        }
      }

      validateSealBagEntry('create', _('#branch_id').val(), _('#seal-bag-number').val(), sealBagNumbers);
    }
  });

  // Update added seal bag row validation
  _('#receipts-create-seal-bag-table').on('change', (e) => {
    if (_(e.target).hasClass('seal-bag-number') && _(e.target).val()) {
      const sealBagNumbers = [];
      if (_('.seal-bag-number').length) {
        for (let index = 0; index < _('.seal-bag-number').length; index++) {
          const element = _('.seal-bag-number')[index];
          sealBagNumbers.push(element.value);
        }
      }

      validateSealBagEntry('update', _('#branch_id').val(), _(e.target).val(), sealBagNumbers);
    }

    if ((_(e.target).hasClass('seal-bag-amount') || _(e.target).attr('id') === 'seal-bag-amount') && _(e.target).val()) {
      // let fieldValue;
      // let inputValue = _(e.target).val();
      // inputValue = inputValue.replace(/[^0-9\+\-\,\.\*\/]/g, '');
      // eval(`fieldValue = ${inputValue}`);
      // _(e.target).val(fieldValue);

      $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': window.Atabase.csrfToken },
      });

      $.ajax({
        type: "POST",
        url: "/receipts/parse-calculation",
        data: `value=${encodeURIComponent(e.target.value)}`,
        success: (response) => {
          // @kijkhier!
          _(e.target).val(response.value);

          // show error message
          if (response.message && response.message.length) {
            if (_(e.target).hasClass('seal-bag-amount')) {
              _(e.target).siblings('.errorMessage').html(response.message);
              _(e.target).addClass('hasError');
            } else {
              _(e.target).siblings('.errorMessage').attr('data-error', response.message);
            }
          } else {
            _(e.target).siblings('.errorMessage').attr('data-error', '');
            if (_(e.target).hasClass('seal-bag-amount')) {
              _(e.target).siblings('.errorMessage').html('');
              _(e.target).removeClass('hasError');
            }
          }

          // update current fields
          if (_(e.target).hasClass('seal-bag-amount') || _(e.target).hasClass('seal-bag-number')) {
            const index = _(e.target).attr('data-index') - 1;
            if (_(e.target).hasClass('seal-bag-amount')) {
              globalReceipt.sealBags[index].amount = _(e.target).val();
            }
            if (_(e.target).hasClass('seal-bag-number')) {
              globalReceipt.sealBags[index].number = _(e.target).val();
            }

            saveToLocalStorage(globalReceipt);
          }

          // add new field
          if (_('#seal-bag-number').val() && _('#seal-bag-amount').val()) {
            const sealBag = buildSealBagObject();
            if (!globalReceipt.sealBagExist(sealBag)) {
              globalReceipt.attributes = getAttributes();
              const index = globalReceipt.addSealBag(sealBag);
              printSealBag(index);
              saveToLocalStorage(globalReceipt);
            } else {
              showSwal('This seal bag is duplicated', 'error');
            }
          }
        }
      })
    }
  });

  _('#add-seal-bags-table-button').on('click', (event) => {
    event.preventDefault();
    // if (validateValues(_('#receipt-create'))) {
    globalReceipt.attributes = getAttributes();
    showSealBagsInputs();
    // }
  });

  _('#date').on('change', (event) => {
    event.preventDefault();

    globalReceipt.attributes = getAttributes();
    saveToLocalStorage(globalReceipt);
  });

  _('#notes').on('change', (event) => {
    event.preventDefault();

    globalReceipt.attributes = getAttributes();
    saveToLocalStorage(globalReceipt);
  });

  _('#branch_id').on('change', (event) => {
    event.preventDefault();

    globalReceipt.attributes = getAttributes();
    saveToLocalStorage(globalReceipt);
  });


  $("#image").change(function() {
    readURL(this);
  });

  _('#receipt-create').on('submit', (event) => {
    event.preventDefault();
    swal({
      title: 'Warning',
      text: _('#receipt-create').attr('data-confirm-text'),
      type: 'warning',
      confirmButtonText: _('#receipt-create').attr('data-confirm-button-text'),
      showCancelButton: true,
      confirmButtonColor: '#2eba62',
      cancelButtonText: _('#receipt-create').attr('data-cancel-button-text'),
    }).then((result) => {
      if (result) {
        dealOtherCase(event);
      }
    });
  });
}

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1])
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

function readURL(input) {
  if (input.files && input.files[0]) {
    const reader = new FileReader();

    reader.onload = function(e) {
      $('#uploadReceiptCheck').attr('src', e.target.result);

      globalReceipt.image_path = e.target.result;
      saveToLocalStorage(globalReceipt);
    }

    reader.readAsDataURL(input.files[0]);
  }

}

function saveToLocalStorage(receipts) {
  localStorage.setItem('receipts', JSON.stringify(receipts));
}

function loadFromLocalStorage(receiptDate) {
  const receipt = JSON.parse(localStorage.getItem('receipts'));
  if (receipt) {
    globalReceipt.attributes = receipt;
    printSealBagFromStorage(receipt);
    receiptDate.setDate(new Date(moment(globalReceipt.date).format('MM/DD/YYYY')));
    _('#notes').val(globalReceipt.notes);

    if (globalReceipt.image_path) {
      $('#uploadReceiptCheck').attr('src', globalReceipt.image_path);
      _('#uploadReceiptImageName').html(globalReceipt.image_name);
      _('.uploadReceiptImage--uploaded').removeClass('hide');

      _('#uploadReceiptCheck').removeClass('hide');
      _('#uploadImageLabel').addClass('hide');
      _('#uploadReceiptImage').addClass('hide');
      _('#preserved_image').val(globalReceipt.image_path);
    }
  }
}

function initReceiptUpdate() {
  _('#receipt-update').on('submit', (event) => {
    event.preventDefault();
    dealOtherCase(event);
  });

  // add money sealBag
  _('#add-seal-bag-button').on('click', (e) => {
    e.preventDefault();
    doInputSwal();
  });
}

export default function initReceipt() {
  const receiptDate = flatpickr('input[name=date]', {
    dateFormat: 'Y-m-d H:i',
    enableTime: true,
    maxDate: 'today',
    defaultDate: 'today',
  });


  if (_('#brand_id').length) {
    globalReceipt.branch = _('#branch_id').val();
  }

  _('#previous_cumulative_check').on('blur', () => {
    if (_('#previous_cumulative').length &&
      _('#previous_cumulative').val() !== _('#previous_cumulative_check').val() &&
      !_('#current_cumulative').parent().parent().hasClass('hide')
    ) {
      showSwal('Current cumulative and previous cumulative value should have the same value', 'warning');
    }
  });

  _('#current_cumulative').on('blur', () => {
    if (parseInt(_('#current_cumulative').val()) <= parseInt(_('#previous_cumulative_check').val()) &&
      !_('#current_cumulative').parent().parent().hasClass('hide')
    ) {
      showSwal('Current cumulative is lower or equal than previous cumulative value', 'warning');
    }
  });

  // delete a receipt
  _('#receipts-delete').on('click', (event) => {
    swal({
      title: 'Delete',
      text: 'Do you really want to delete the deposit?',
      type: 'warning',
      confirmButtonText: 'Confirm',
      showCancelButton: true,
      confirmButtonColor: '#e74c3c',
    }).then((result) => {
      if (result) {
        axios.delete(_(event.target).attr('data-url'))
          .then((response) => {
            makeSwal(response.data);
          });
      }
    });
  });

  if (_('#receipt-create').length) {
    initReceiptCreate();
  } else {
    initReceiptUpdate();
  }

  if (_('#uploadReceiptImage').length) {
    _('.uploadImageContainer').on('click', () => {
      $('#image').trigger('click');
    });

    _('#image').on('change', (event) => {
      _('#uploadReceiptImageName').html(event.target.files[0].name);

      globalReceipt.image_name = event.target.files[0].name;
      saveToLocalStorage(globalReceipt);

      _('#uploadReceiptCheck').removeClass('hide');
      _('#uploadImageLabel').addClass('hide');
      _('#uploadReceiptImage').addClass('hide');
    });
  }

  loadFromLocalStorage(receiptDate);
}
