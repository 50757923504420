import makeSwal from './helpers';

$(() => {
  $.ajaxSetup({
    headers: { 'X-CSRF-TOKEN': window.Atabase.csrfToken },
  });
});

_(document).ready(() => {
  if (!$.fn.dataTable.isDataTable('#debtors-index')) {
    $('#debtors-index').dataTable({
      order: [[1, 'asc']],
      ajax: {
        url: _('#debtors-index').attr('data-url-table'),
      },
      columns: [
        { data: 'id', width: '80px' },
        { data: 'number' },
        { data: 'name' },
        { data: null,
          name: '',
          width: '120px',
          sortable: false,
          searchable: false,
          render(data, type, row) {
            if (data.details) {
              return `<a href="${_('#debtors-index').attr('data-url-detail').replace('_id_', row.id)}"><i class="fa fa-search"></i> Details</a>`;
            }
            return '-';
          } },
      ],
    });
  }

  if (!$.fn.dataTable.isDataTable('#debtors-show-branches')) {
    $('#debtors-show-branches').dataTable({
      order: [[1, 'asc']],
      ajax: {
        url: $('#debtors-show-branches').data('url-table'),
      },
      columns: [
        {data: 'id', width: '80px'},
        {data: 'name'},
        {data: null, width: '120px', sortable: false, searchable: false, render: (data, type, row) => {
          return '<a class="table-show-link" href="'+$('#debtors-show-branches').data('url-detail').replace('_id_', row.id)+'"><i class="fa fa-search"></i> Details</a>';
        }},
      ]
    });
  }
});

_('#debtor-store').on('submit', (event) => {
  event.preventDefault();
  axios.post(_(event.target).attr('action'), _(event.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((error) => {
      makeSwalValidation(error);
    });
});


_('#debtor-edit').on('submit', (event) => {
  event.preventDefault();
  axios.post(_(event.target).attr('action'), _(event.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#debtor-show-delete').on('click', (event) => {
  swal({
    title: 'Delete Debtor',
    text: 'Do you really want to delete the debtor?',
    type: 'warning',
    confirmButtonText: 'Delete',
    showCancelButton: true,
    confirmButtonColor: '#e74c3c',
  }).then((result) => {
    if (result) {
      axios.delete(_(event.target).attr('data-url'))
        .then((response) => {
          makeSwal(response.data);
        });
    }
  });
});
