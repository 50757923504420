import makeSwal from './helpers';

_('#turnstiles-day-store').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_(document).ready(() => {
  const turnstilesDayCreateMonth = new Date();
  flatpickr('#turnstiles-day-create input[name=start_at]', {
    dateFormat: 'Y-m',
    maxDate: turnstilesDayCreateMonth,
  });
});
