import Handsontable from 'Handsontable';

const Highcharts = require('highcharts');

function formatNumber(num) {
  if (Number.isInteger(num)) {
    return num.toString(); // Return as string to preserve integer format
  } else {
    return num.toFixed(1);
  }
}

function formatNumberPercentage(num) {
  if (Number.isInteger(num)) {
    return num.toString(); // Return as string to preserve integer format
  } else {
    return num.toFixed(2);
  }
}

function getCharts() {
  const startDate = _('input[name=revenue-filter-start]').val();
  const endDate = _('input[name=revenue-filter-end]').val();
  axios.get(`${_('#revenue-filter-submit').attr('data-ajax-url')}?start=${startDate}&end=${endDate}`)
    .then((response) => {
      const revenueChart = Highcharts.chart('panel-revenue-chart-revenue', {
        title: {
          text: 'Revenue',
        },
        series: [{
          name: 'Revenue',
          data: response.data.charts.revenue,
        }],
        xAxis: {
          categories: response.data.xAxis,
        },
        credits: {
          enabled: false,
        },
      });

      const visitChart = Highcharts.chart('panel-revenue-chart-visits', {
        title: {
          text: 'Visitors',
        },
        series: [{
          name: 'Visitors',
          data: response.data.charts.visits,
        }],
        xAxis: {
          categories: response.data.xAxis,
        },
        credits: {
          enabled: false,
        },
      });
      const paymentChart = Highcharts.chart('panel-revenue-chart-payment-method', {
        title: {
          text: 'Payment Methods',
        },
        chart: {
          type: 'column',
        },
        series: [{
          name: 'Coin',
          data: response.data.charts.payment.coin,
        }, {
          name: 'Contact Less',
          data: response.data.charts.payment.contact_less,
        }, {
          name: 'Token',
          data: response.data.charts.payment.token,
        }, {
          name: 'POS',
          data: response.data.charts.payment.pos,
        }, {
          name: 'Shower',
          data: response.data.charts.payment.shower,
        }, {
          name: 'Vouchers',
          data: response.data.charts.payment.vouchers,
        }],
        xAxis: {
          categories: response.data.xAxis,
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          },
        },
        credits: {
          enabled: false,
        },
      });

      const data = [];
      let offset = 0;

      for(const year in response.data.table){
        let yearTotal = 0;
        let yearCoin = 0;
        let yearCoinPercentage = 0;
        let yearContactLess = 0;
        let yearContactLessPercentage = 0;
        let yearToken = 0;
        let yearTokenPercentage = 0;
        let yearManual = 0;
        let yearManualPercentage = 0;
        let yearPos = 0;
        let yearPosPercentage = 0;
        let yearShower = 0;
        let yearShowerPercentage = 0;
        let yearVouchers = 0;
        let yearVouchersPercentage = 0;
        let yearServiceVisits = 0;
        let yearServiceVisitsPercentage = 0;

        for(const month in response.data.table[year]) {
          let monthTotal = 0;
          let monthCoin = 0;
          let monthCoinPercentage = 0;
          let monthContactLess = 0;
          let monthContactLessPercentage = 0;
          let monthToken = 0;
          let monthTokenPercentage = 0;
          let monthManual = parseInt(response.data.table[year][month].manual) || 0;
          let monthManualPercentage = 0;
          let monthPos = 0;
          let monthPosPercentage = 0;
          let monthShower = 0;
          let monthShowerPercentage = 0;
          let monthVouchers = 0;
          let monthVouchersPercentage = 0;
          let monthServiceVisits = 0;
          let monthServiceVisitsPercentage = 0;
          const monthStartOffset = offset;

          if (monthManual > 0) { // Assume KABA turnstile
            monthTotal += monthManual;
          }

          for(const day in response.data.table[year][month]) {
            if (day !== 'manual') {
              const row = response.data.table[year][month][day];
              const dayTotal = row.total;

              monthCoin += parseFloat(row.coin) || 0;
              monthContactLess += parseFloat(row.contactLess) || 0;
              monthToken += parseInt(row.token) || 0;
              monthManual += parseInt(row.manual) || 0;
              monthPos += parseInt(row.pos) || 0;
              monthShower += parseInt(row.shower) || 0;
              monthVouchers += parseFloat(row.vouchers) || 0;
              monthServiceVisits += parseFloat(row.servicevisits) || 0;
              monthTotal += dayTotal;

              // Days
              data.splice(monthStartOffset, 0, [
                '',
                '',
                day,
                formatNumber(dayTotal),
                formatNumber(row.coin) || 0,
                `<span style="font-size: 12px; color: #aaa">${formatNumberPercentage(row.coinPercentage) || 0}%</span>`,
                formatNumber(row.contactLess) || 0,
                `<span style="font-size: 12px; color: #aaa">${formatNumberPercentage(row.contactLessPercentage) || 0}%</span>`,
                parseInt(row.token) || 0,
                `<span style="font-size: 12px; color: #aaa">${(Math.floor(parseFloat(row.tokenPercentage) * 10) % 10 == 0 ? Math.round(parseFloat(row.tokenPercentage)) : row.tokenPercentage.toFixed(1)) || 0}%</span>`,
                parseInt(row.manual) || 0,
                `<span style="font-size: 12px; color: #aaa">${(Math.floor(parseFloat(row.manualPercentage) * 10) % 10 == 0 ? Math.round(parseFloat(row.manualPercentage)) : row.manualPercentage.toFixed(1)) || 0}%</span>`,
                parseInt(row.pos) || 0,
                `<span style="font-size: 12px; color: #aaa">${(Math.floor(parseFloat(row.posPercentage) * 10) % 10 == 0 ? Math.round(parseFloat(row.posPercentage)) : row.posPercentage.toFixed(1)) || 0}%</span>`,
                parseInt(row.shower) || 0,
                `<span style="font-size: 12px; color: #aaa">${(Math.floor(parseFloat(row.showerPercentage) * 10) % 10 == 0 ? Math.round(parseFloat(row.showerPercentage)) : row.showerPercentage.toFixed(1)) || 0}%</span>`,
                formatNumber(row.vouchers) || 0,
                `<span style="font-size: 12px; color: #aaa">${0}%</span>`,
                formatNumber(row.servicevisits) || 0,
                `<span style="font-size: 12px; color: #aaa">${0}%</span>`,
              ]);
              offset += 1;
            }
          }

          monthCoinPercentage = (monthCoin / monthTotal * 100) || 0;
          monthContactLessPercentage = (monthContactLess / monthTotal * 100) || 0;
          monthTokenPercentage = (monthToken / monthTotal * 100) || 0;
          monthManualPercentage = (monthManual / monthTotal * 100) || 0;
          monthPosPercentage = (monthPos / monthTotal * 100) || 0;
          monthShowerPercentage = (monthShower / monthTotal * 100) || 0;
          monthVouchersPercentage = (monthVouchers / monthTotal * 100) || 0;
          monthServiceVisitsPercentage = monthServiceVisits / monthTotal * 100 || 0;

          // Months
          data.splice(monthStartOffset, 0, [
            `<b>${year}</b>`,
            `<b>${month}</b>`,
            '<b>Total</b>',
            `<b>${formatNumber(monthTotal) || 0}</b>`,
            `<b>${formatNumber(monthCoin) || 0}</b>`,
            `<b style="font-size: 12px; color: #aaa">${formatNumberPercentage(monthCoinPercentage) || 0}%</b>`,
            `<b>${formatNumber(monthContactLess) || 0}</b>`,
            `<b style="font-size: 12px; color: #aaa">${formatNumberPercentage(monthContactLessPercentage) || 0}%</b>`,
            `<b>${parseInt(monthToken) || 0}</b>`,
            `<b style="font-size: 12px; color: #aaa">${(Math.floor(parseFloat(monthTokenPercentage) * 10) % 10 == 0 ? Math.round(parseFloat(monthTokenPercentage)) : monthTokenPercentage.toFixed(1)) || 0}%</b>`,
            `<b>${parseInt(monthManual) || 0}</b>`,
            `<b style="font-size: 12px; color: #aaa">${(Math.floor(parseFloat(monthManualPercentage) * 10) % 10 == 0 ? Math.round(parseFloat(monthManualPercentage)) : monthManualPercentage.toFixed(1)) || 0}%</b>`,
            `<b>${parseInt(monthPos) || 0}</b>`,
            `<b style="font-size: 12px; color: #aaa">${(Math.floor(parseFloat(monthPosPercentage) * 10) % 10 == 0 ? Math.round(parseFloat(monthPosPercentage)) : monthPosPercentage.toFixed(1)) || 0}%</b>`,
            `<b>${parseInt(monthShower) || 0}</b>`,
            `<b style="font-size: 12px; color: #aaa">${(Math.floor(parseFloat(monthShowerPercentage) * 10) % 10 == 0 ? Math.round(parseFloat(monthShowerPercentage)) : monthShowerPercentage.toFixed(1)) || 0}%</b>`,
            `<b>${formatNumber(monthVouchers) || 0}</b>`,
            `<b style="font-size: 12px; color: #aaa">${0}%</b>`,
            `<b>${formatNumber(monthServiceVisits) || 0}</b>`,
            `<b style="font-size: 12px; color: #aaa">${0}%</b>`,
          ]);
          offset += 1;

          data.push([]);
          offset += 1;

          yearTotal += parseFloat(monthTotal) || 0;

          yearCoin += parseFloat(monthCoin) || 0;
          yearContactLess += parseFloat(monthContactLess) || 0;
          yearToken += parseInt(monthToken) || 0;
          yearManual += parseInt(monthManual) || 0;
          yearPos += parseInt(monthPos) || 0;
          yearShower += parseInt(monthShower) || 0;
          yearVouchers += parseFloat(monthVouchers) || 0;
          yearServiceVisits += parseFloat(monthServiceVisits) || 0;

          yearCoinPercentage = yearTotal != 0 ? yearCoin / yearTotal : 0;
          yearContactLessPercentage = yearTotal != 0 ? yearContactLess / yearTotal : 0;
          yearTokenPercentage = yearTotal != 0 ? yearToken / yearTotal : 0;
          yearManualPercentage = yearTotal != 0 ? yearManual / yearTotal : 0;
          yearPosPercentage = yearTotal != 0 ? yearPos / yearTotal : 0;
          yearShowerPercentage = yearTotal != 0 ? yearShower / yearTotal : 0;
          yearVouchersPercentage = yearTotal != 0 ? yearVouchers / yearTotal : 0;
          yearServiceVisitsPercentage = yearTotal != 0 ? yearServiceVisits / yearTotal : 0;
        }

        // Totals
        data.push([
          year,
          'Total',
          '',
          formatNumber(yearTotal),
          formatNumber(yearCoin),
          '',
          formatNumber(yearContactLess),
          '',
          yearToken,
          '',
          yearManual,
          '',
          yearPos,
          '',
          yearShower,
          '',
          formatNumber(yearVouchers),
          '',
          formatNumber(yearServiceVisits),
          '',
        ]);
        offset += 1;
        data.push([]);
        offset += 1;
      }

      if (typeof window.visitTable !== 'undefined') {
        window.visitTable.destroy();
      }

      window.visitTable = new Handsontable(document.getElementById('branch-detail-visit-table'), {
        data: data,
        colHeaders: ['Year', 'Month', 'Day', 'Total', 'Coin', '', 'Contact Less', '', 'Token', '', 'Manual', '', 'POS', '', 'Shower', '', 'vouchers', '', 'Service Visits', ''],
        readOnly: true,
        stretchH: 'all',
        columns: [
          { data: '0', renderer: 'html' },
          { data: '1', renderer: 'html' },
          { data: '2', renderer: 'html' },
          { data: '3', renderer: 'html' },
          { data: '4', renderer: 'html' },
          { data: '5', renderer: 'html' },
          { data: '6', renderer: 'html' },
          { data: '7', renderer: 'html' },
          { data: '8', renderer: 'html' },
          { data: '9', renderer: 'html' },
          { data: '10', renderer: 'html' },
          { data: '11', renderer: 'html' },
          { data: '12', renderer: 'html' },
          { data: '13', renderer: 'html' },
          { data: '14', renderer: 'html' },
          { data: '15', renderer: 'html' },
          { data: '16', renderer: 'html' },
          { data: '17', renderer: 'html' },
          { data: '18', renderer: 'html' },
          { data: '19', renderer: 'html' },
        ],
      });
    })
    .catch((axiosError) => {
      //
    });
}

function handleTabActivation() {
  if ($('#panel-revenue.active').length) {
    getCharts();

    _('#revenue-filter-submit').on('click', () => {
      getCharts();
    });

    flatpickr('input[name=revenue-filter-start]');
    flatpickr('input[name=revenue-filter-end]');
  }
}

_(document).ready(() => {
  handleTabActivation();
});

$('.tabs').on('change.zf.tabs', () => {
  handleTabActivation();
});
