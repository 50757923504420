import makeSwal from "./helpers";

_('#entity-create').on('submit', (e) => {
  e.preventDefault();
  axios.post(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#entity-update').on('submit', (e) => {
  e.preventDefault();
  axios.put(_(e.target).attr('action'), _(e.target).serialize())
    .then((response) => {
      makeSwal(response.data);
    })
    .catch((axiosError) => {
      makeSwalValidation(axiosError);
    });
});

_('#entity-delete').on('click', (event) => {
  swal({
    title: 'Delete',
    text: 'Do you really want to delete the branch entity?',
    type: 'warning',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
    confirmButtonColor: '#e74c3c',
  }).then((result) => {
    if (result) {
      axios.delete(_(event.target).attr('data-url'))
        .then((response) => {
          makeSwal(response.data);
        });
    }
  });
});
