import makeSwal from './helpers';

_(document).ready(() => {
  _(document).on('click', '.generateInvoice-btn', (e) => {
    e.preventDefault();
    const table = $(e.target).closest('table');
    const button = $(e.target).closest('.generateInvoice-btn');
    let url = $(button).attr('data-url');
    const period = $(button).attr('data-period');
    const data = {
      period: period,
    };

    const checkedInputs = $(table).find('.branch_id_marker:checked');
    if (checkedInputs.length) {
      url += '?branch_ids=';
      $(checkedInputs).each((i, element) => {
        url += `${$(element).val()},`;
      });
    }

    swal({
      type: "question",
      title: `Generate invoices ${period}`,
      html: `Are you sure you want to generate invoices for<br>period ${period}? The uninvoiced amounts will be marked as invoiced and can't be invoiced again.`,
      confirmButtonText: "Generate invoices",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then((confirmed) => {
      if (confirmed) {
        axios.post(url, data)
          .then((response) => {
            if (response.data.swal.type === 'success') {
              window.location = response.data.xml_temporary_url;
            }
          });
      }
    });
  });
});
