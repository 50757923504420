_(document).ready(() => {
  if (!$.fn.dataTable.isDataTable('#audit-logs-index')) {
    $('#audit-logs-index').dataTable({
      order: [[4, 'asc']],
      ajax: {
        url: $('#audit-logs-index').data('url-table'),
        data(data) { },
      },
      columns: [
        { data: 'id' },
        { data: 'user.name' },
        { data: 'action' },
        { data: 'entity' },
        { data: 'updated_at' },
        { data: null,
          name: '',
          sortable: false,
          searchable: false,
          render(data, type, row) {
            console.log(data);

            return '<a class="table-show-link" href="'+$('#audit-logs-index').data('url-detail').replace('_id_', row.id)+'"><i class="fa fa-search"></i> Details</a>';
          }},
      ]
    });
  }
});
