import uniq from 'lodash/uniq';

import Questionaire from './questionaire.class';

let isUpdating = false;

function buildQuestionObject(qname) {
  return { name: qname, value: null };
}

function buildQuestionsObject() {
  const obj = {};
  const categories = _('.questionaire__category--holder');

  for (let i = 0; i < categories.length; i += 1) {
    const questionFields = _(categories[i]).find('.question--input');

    let questions = [];
    for (let j = 0; j < questionFields.length; j += 1) {
      questions.push(_(questionFields[j]).attr('name'));
    }

    questions = uniq(questions);

    const catQuestions = [];
    for (let k = 0; k < questions.length; k += 1) {
      catQuestions.push(buildQuestionObject(questions[k]));
    }

    obj[i] = {};
    obj[i].questions = catQuestions;
  }


  return obj;
}

function writeToLocalStorage(questionaire) {
  localStorage.setItem('2theloo__questionaire', JSON.stringify(questionaire.questions));
}


function saveQuestionaire(questionaire, completed) {
  if (isUpdating) {
    return;
  }

  writeToLocalStorage(questionaire);

  const payload = {};
  for (let i = 0; i < Object.keys(questionaire.questions).length; i += 1) {
    const questions = Object.values(questionaire.questions)[i].questions;

    for (let j = 0; j < questions.length; j += 1) {
      payload[questions[j].name] = questions[j].value;

      if (questions[j].name !== 'files[]') {
        if (_(`#${questions[j].name}-notes`).length) {
          payload[`${questions[j].name}-notes`] = _(`#${questions[j].name}-notes`).val();
        }
      }
    }
  }

  if (questionaire.questionaireId) {
    payload.id = questionaire.questionaireId;
  }

  if ($('#questionaireForm').attr('data-checklist')) {
    payload.id = $('#questionaireForm').attr('data-checklist');
  }

  if (questionaire.branchId) {
    payload.branchId = questionaire.branchId;
  }

  if (completed != undefined) {
    payload.is_completed = true;
  }

  payload.average_rating = questionaire.getAverageForAllCategories();

  const fd = new FormData();

  for (const key in payload) {
    fd.append(key, payload[key]);
  }

  if ($('.question--file').length) {

    $('.question--file').each(function (i, input) {
      const file = $('.question--file')[i].files[0];
      fd.append('files['+i+']', file);
    });
  }

  setIsUpdating();

  const url = `${window.location.pathname.split('/add')[0]}/add`;
  axios.post(url, fd)
  .then((response) => {
    if (response.data.id) {
      questionaire.questionaireId = response.data.id;
    }
    if (response.data.completed == "1") {
      localStorage.removeItem('2theloo__questionaire');
      document.location = url;
    }

    setIsFinishedUpdating();
  })
  .catch(() => {
    setIsFinishedUpdating();
  });
}

function setIsUpdating() {
  $('.questionaire__control').addClass('disabled');
  isUpdating = true;
}

function setIsFinishedUpdating() {
  $('.questionaire__control').removeClass('disabled');
  isUpdating = false;
}

function updateQuestionaire(questionaire, completed) {
  let value;
  let name;
  let dataType;
  let notes;

  if (!_(_('.questionaire__category--holder')[questionaire.currentCategory]).find('.category__question')[questionaire.currentQuestion]) {
    return;
  }

  const inputs = _(_(_('.questionaire__category--holder')[questionaire.currentCategory])
    .find('.category__question')[questionaire.currentQuestion]).find('.question--input');

  if (inputs.length > 1) {
    for (let i = 0; i < inputs.length; i += 1) {
      if (inputs[i].checked) {
        value = _(inputs[i]).val();
        name = _(inputs[i]).attr('name');
        dataType = _(inputs[i]).attr('data-type-of-value');
        notes = _(`#${name}-notes`).val();
      }
    }
  } else if (inputs.length === 1) {
    // This is for the "comment" and "upload file" steps
    value = inputs.val();
    name = inputs.attr('name');
    dataType = inputs.attr('data-type-of-value');
  } else {
    return;
  }

  if (_(`.answer__text[data-question="${name}"]`).length) {
    let printVal;

    switch (dataType) {
      case 'boolean': printVal = (value === '1' ? 'Yes' : 'No'); break;
      default: printVal = value; break;
    }

    _(`.answer__text[data-question="${name}"]`)[0].innerText = printVal;
  }

  if (_(`.answer__text[data-question="${name}"]`).length) {
    _(`.answer__notes[data-notes="${name}"]`)[0].innerText = notes;
  }

  questionaire.update(value);
  if (dataType === 'integer') {
    questionaire.updateForAverage(value);
    _(`.category-average[data-category="${questionaire.currentCategory}"]`)[0].innerText = questionaire.getAverageForCategory();
  }
  saveQuestionaire(questionaire, completed);
}

function fillIncompleteQuesionaire(data, questionaire) {
  questionaire.branchId = data.branchId;
  questionaire.id = data.id;

  for (let i = 0; i < Object.keys(questionaire.questions).length; i += 1) {
    const cat = questionaire.questions[i];

    for (let j = 0; j < cat.questions.length; j += 1) {
      const question = cat.questions[j];
      question.value = data[question.name];
    }
  }
}

function setInputFields(data) {
  for (let i =0; i < Object.keys(data).length; i += 1) {
    if (Object.keys(data)[i] !== 'id'
      && Object.keys(data)[i] !== 'branchId'
      && Object.keys(data)[i] !== 'completed'
      && data[Object.keys(data)[i]] !== null
    ) {
      const input = _(`[name="${Object.keys(data)[i]}"]`);

      if (input.attr('type') === 'radio') {
        const radio = _(`[name="${Object.keys(data)[i]}"][value="${data[Object.keys(data)[i]]}"]`);
        radio.attr('checked', true);
      } else {
        input.val(data[Object.keys(data)[i]]);
      }
    }
  }
}

function getIncompleteQuestionaire(questionaire) {
  // Check if we are in edit mode
  if (window.location.toString().indexOf('/edit') > 0) {
    // Edit mode, don't do this!
    return;
  }

  const url = window.location.toString().replace('/create', '/incomplete');
  axios.get(url)
  .then((response) => {
    fillIncompleteQuesionaire(response.data, questionaire);
    setInputFields(response.data);
  })
  .catch(() => {
    // console.log('No incomplete checklist available');
  });
}

function toggleInputVisibility(questionaire) {
  _('.questionaire__category--holder.active').removeClass('active');
  _('.category__question.active').removeClass('active');

  const currentCategory = _(_('.questionaire__category--holder')[questionaire.currentCategory]).addClass('active');
  _(currentCategory.find('.category__question')[questionaire.currentQuestion]).addClass('active');
}

export default function initQuestionaire() {
  const branchId = _('#questionaireForm').attr('data-branch-id');

  const questions = buildQuestionsObject();
  const questionaire = new Questionaire(questions, branchId);
  let currentQuestion = 0;

  getIncompleteQuestionaire(questionaire);

  _('.questionaire__control').on('click', (e) => {
    e.preventDefault();

    // Next
    if (_(e.target).hasClass('questionaire__control--next')) {
      currentQuestion += 1;
      updateQuestionaire(questionaire);
      questionaire.goToNextQuestion();

      if (questionaire.summaryActive) {
        _('.questionaire__category--holder').removeClass('active');
        _(`[data-summary="${questionaire.currentCategory}"]`).addClass('active');

        return;
      }

      // Close the summary page if active
      _('.questionaire__category--summary.active').removeClass('active');
      const numCategories = Object.keys(questionaire.questionaire).length;
      const numQuestions = questionaire.questionaire[numCategories - 1].questions.length;

      if (questionaire.categoryIndex === Object.keys(questionaire.questionaire).length - 1 &&
        questionaire.questionIndex === numQuestions - 1) {
        _('.questionaire__control--next').addClass('hide');
        _('.questionaire__control--send').removeClass('hide');
      }

      if (questionaire.questionIndex === 0) {
        _('.questionaire__control--previous').addClass('hide');
      }
    } else if (_(e.target).hasClass('questionaire__control--send')) {
      updateQuestionaire(questionaire, true);
    } else {
      // Previous
      currentQuestion -= 1;
      _('.questionaire__control--next').removeClass('hide');
      _('.questionaire__control--send').addClass('hide');
      questionaire.goToPreviousQuestion();

      _('.questionaire__category--summary.active').removeClass('active');

    }

    if (currentQuestion === 0) {
      _('.questionaire__control--previous').addClass('hide');
    } else {
      _('.questionaire__control--previous').removeClass('hide');
    }

    toggleInputVisibility(questionaire);
  });
}
