export default class Receipt {
  constructor() {
    this.date = null;
    this.currency_iso = null;
    this.turnstile_id = null;
    this.sealBags = [];
    this.image_path = null;
    this.image_name = null;
    this.notes = null;
    this.total_amount = null;
    this.previous_accumulate_check = null;
    this.current_accumulate = null;
    this.branch = null;
  }

  set attributes(attributes) {
    this.date = attributes.date;
    this.currency_iso = attributes.currency_iso;
    this.turnstile_id = attributes.turnstile_id;
    this.image_path = attributes.image_path;
    this.notes = attributes.notes;
    this.total_amount = attributes.total_amount;
    this.previous_accumulate_check = attributes.previous_accumulate_check;
    this.current_accumulate = attributes.current_accumulate;
    this.branch = attributes.branch;
    this.image_name = attributes.image_name;
  }

  /**
   * @param sealBag
   * @returns {boolean}
   */
  sealBagExist(sealBag) {
    let exist = false;

    this.sealBags.forEach((element) => {
      if (sealBag.number === element.number) {
        exist = true;
      }
    });

    return exist;
  }

  addSealBag(sealBag) {
    return this.sealBags.push(sealBag);
  }

  deleteSealBag(number) {
    for (let i = 0; i < this.sealBags.length; i++) {
      if (this.sealBags[i].number === number) {
        this.sealBags.splice(i, 1);
      }
    }
  }

  getSealBags() {
    return this.sealBags;
  }

}
