import makeSwal from "./helpers";

_(document).ready(() => {
  _('#notify-Btn').on('click', (e) => {
    e.preventDefault();
    const url = _(e.target).attr('data-url');

    swal({
      title: 'Send email notifications',
      text: 'You are about to send an e-mail to the selected users based on the news item visibility (country & branch). Do you want to continue?',
      type: 'question',
      confirmButtonText: 'Notify',
      cancelButtonText: 'Cancel',
      showCancelButton: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios.get(url).then((response) => {
          makeSwal(response.data);
        });
      }
    });
  });

  _('#news-show-delete').on('click', (event) => {
    swal({
      title: 'Delete news',
      text: 'Do you really want to delete the news item?',
      type: 'warning',
      confirmButtonText: 'Confirm',
      showCancelButton: true,
      confirmButtonColor: '#e74c3c',
    }).then((result) => {
      if (result) {
        axios.delete(_(event.target).attr('data-url'))
        .then(() => {
          window.location = '/news';
        });
      }
    });
  });


  // show branches multiselect when not using "all" branches
  $('#branches_all').on('click', function (e) {
    let checked = e.currentTarget.checked;

    $('#news-hidden').css('display', checked ? 'none' : 'table-row');
  });

  $('#countrySelector').on('change', (e) => {
    let url = _(e.target).attr('data-load');
    const country_ids = $('#countrySelector').val();
    const country_string = country_ids.join('&countries[]=');
    url += `?countries[]=${country_string}`;

    axios.get(url).then((response) => {
      const dropdown = $('<select id="branchSelector" multiple></select>');

      for (let i in response.data) {
        $(dropdown).append(`<option value="${response.data[i].value}">${response.data[i].label}</option>`);
      }

      $('#branchSelectColumn').html(dropdown);

      new Choices(document.getElementById('branchSelector'));
    });
  });

  // make the table view in the index a datatable
  if (!$.fn.dataTable.isDataTable('#news-index-table')) {
    $('#news-index-table').dataTable({
      order: [[0, 'desc']],
      ajax: $('#news-index-table').attr('data-url-table'),
      columns: [
        { data: 'created_at', width: '190px' },
        { data: 'title', sortable: false },
        { data: 'access_levels', sortable: false },
        { data: 'status' },
        { data: null, name: '', width: '120px', sortable: false, searchable: false, render: function(data, type, row) {
          return '<a class="table-show-link" href="/news/' + row.id + '"><i class="fa fa-search"></i> Details</a>';
        }},
      ]
    });
  }
})
