function getYear() {
  return $('#yearFilter').val();
}

function getTurnstileId() {
  return $('input[name=turnstile_id]').val();
}

function url() {
  return `${(new URL(window.location.href)).origin}/turnstiles/monthly/get`;
}

function render(result) {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Object.keys(result).length; i++) {
    $('#visitorsMonthlyTable')
      .append(`<tr>
        <td>${result[i].date} <input type="hidden" name="row[${result[i].date}][id]" value="${result[i].id}"></td>
        <td><input class="tar" type="number" name="row[${result[i].date}][visits]" value="${result[i].visits}"></td>
        <td><input class="tar" type="number" name="row[${result[i].date}][visits_cumulative]" value="${result[i].visits_cumulative}"></td>
        <td><input class="tar" type="number" name="row[${result[i].date}][servicevisits]" value="${result[i].servicevisits}"></td>
        <td><input class="tar" type="number" name="row[${result[i].date}][servicevisits_cumulative]" value="${result[i].servicevisits_cumulative}"></td>
      </tr>`);
  }
}

$(document).ready(() => {
  // eslint-disable-next-line no-use-before-define
  let newUrl = new URL(url());
  newUrl.searchParams.append('turnstileId', getTurnstileId());
  newUrl.searchParams.append('currentYear', getYear());

  // Default load tab
  $.ajax({
    url: newUrl.href,
    success(result) {
      render(result);
    }
  });

  // On change load tab
  $('#yearFilter').change(() => {
    newUrl = new URL(url());
    newUrl.searchParams.append('turnstileId', getTurnstileId());
    newUrl.searchParams.append('currentYear', getYear());

    $.ajax({
      url: newUrl.href,
      success(result) {
        $('#visitorsMonthlyTable > tbody').html('');
        render(result);
      }
    });
  });
});
